import React from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import LoginPage from './pages/LoginPage'
import Home from './pages/Home'
import Images from './components/Images'
import CreateMessage from './components/CreateMessage'
import CheckInbox from './components/CheckInbox'
import UploadImages from './components/UploadImages'
import CreateProject from './components/CreateProject'
import CreateVolunteer from './components/CreateVolunteer'
import CreateUser from './components/CreateUser'
import UserProjects from './components/UserProjects'
import Jobs from './pages/Jobs'
import CreateTask from './components/CreateTask'
import ContactMe from './pages/ContactMe'
import SharedProjects from './components/SharedProjects'
import UserInfo from './components/UserInfo'
import MemberRequest from './components/MemberRequest'
import UserTasks from './components/UserTasks'
import AcceptFriendsRequest from './components/AcceptFriendsRequest'
import ShowImages from './components/ShowImages'
import SearchSkills from './components/SearchSkills'
import LoginBarTop from './components/LoginBarTop'
import OurMission from  './pages/OurMission'
import GainRewards from  './pages/GainRewards'
import WhyVolunteer from  './pages/WhyVolunteer'
import Blogs from './pages/Blogs'
import SneakPeak from './pages/SneakPeak'
import Products from './pages/Products'
import PasswordRecoveryForm from './components/PasswordRecoveryForm'
import ResetPassword from './components/ResetPassword'
import AboutUs from './pages/AboutUs'
import Calendar from './components/Calendar'
import Privacy from './pages/Privacy'
import HomePage from './homePage/HomePage'
import Tweets from './pages/Tweets'

const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route hreflang="Login" path="/Login" element={<LoginPage/>}/>
          <Route path="/AboutUs" element={<AboutUs/>}/>
          <Route path="/Tweets" element={<Tweets />} />
          <Route hreflang="Download App" path="/SneakPeak" element={<SneakPeak />}/>
          <Route path="/OurMission" element={<OurMission/>}/>
          <Route path="/Blogs" element={<Blogs/>}/>
          <Route path="/Blogs/:blogTitle" element={<Blogs />}/>    
          <Route path="/GainRewards" element={<GainRewards/>}/>
          <Route path="/WhyVolunteer" element={<WhyVolunteer/>}/>
          <Route path="/Home" element={<Home/>} />
          <Route path="/Products" element={<Products/>} />
          <Route path="/Jobs" element={<Jobs /> } />
          <Route path="/Images" element={<Images />} />
          <Route path="/CreateTask" element={<CreateTask />} />
          <Route path="/CreateVolunteer" element={<CreateVolunteer />} />
          <Route path="/CreateProject" element={<CreateProject />} />
          <Route path="/UploadImages" element={<UploadImages />} />
          <Route path="/CreateUser" element={<CreateUser />} /> 
          <Route path="/CreateMessage" element={<CreateMessage />} /> 
          <Route path="/CheckInbox" element={<CheckInbox />}/>    
          <Route path="/UserProjects" element={<UserProjects />}/>    
          <Route path="/UserProjects/:userId" element={<UserProjects />}/>    
          <Route path="/ContactUs" element={<ContactMe />} />
          <Route path="/SharedProjects" element={<SharedProjects/>}/>
          <Route path="/UserInfo" element={<UserInfo/>}/>
          <Route path="/MemberRequest" element={<MemberRequest/>}/>
          <Route path="/Images" element={<Images/>}/>
          <Route path="/UserTasks" element={<UserTasks/>}/>
          <Route path="/AcceptFriendsRequest"  element={<AcceptFriendsRequest/>}/>
          <Route path="/LoginBarTop" element={<LoginBarTop/>}/>
          <Route path="/SearchSkills"  element={<SearchSkills/>}/>
          <Route path="/ShowImages"  element={<ShowImages/>}/>
          <Route path="/ResetPassword/:userId/:email" element={<ResetPassword/>}/>
          <Route path="/PasswordRecoveryForm" element={<PasswordRecoveryForm />}/>
          <Route path="/Calendar" element={<Calendar />}/>
          <Route path="/Privacy" element={<Privacy />}/>
        </Routes>
    </BrowserRouter>
  );
}
export default Routing