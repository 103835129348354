import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { Typography,TextField } from '@mui/material'

const Checklist = () => {
  const [checklists, setChecklists] = useState([]);
  const [newChecklist, setNewChecklist] = useState('');

  const handleAddChecklist = () => {
    if (newChecklist.trim() !== '') {
      setChecklists([...checklists, { text: newChecklist, checked: false }]);
      setNewChecklist('');
    }
  };

  const handleToggle = (index) => {
    const updatedChecklists = [...checklists];
    updatedChecklists[index].checked = !updatedChecklists[index].checked;
    setChecklists(updatedChecklists);
  };

  return (
    <div style={{marginTop:"4rem"}}>
     <Typography variant="body1" >
                Check List
                </Typography>
      <TextField style={{marginTop:"1rem"}}
        label="Add a checklist item"
        value={newChecklist}
        onChange={(e) => setNewChecklist(e.target.value)}
        variant="outlined"
      />
      <Button style={{marginTop:"1.5rem"}} variant="text" color="primary" onClick={handleAddChecklist}>
        Add
      </Button>
      <List>
        {checklists.map((item, index) => (
          <ListItem key={index}>
            <Checkbox
              checked={item.checked}
              onChange={() => handleToggle(index)}
            />
         <Typography variant="body1" >   <ListItemText primary={item.text} /></Typography> 
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default Checklist;
