import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { getOnlineUsers } from '../services/OnlineService';
import { useLocation } from 'react-router-dom';


const UserBlogs = ({ user }) => {
  const location = useLocation();
  let getUser = user;
  const state = location.state || {};
  const realUser = state.realUser || '';
  const [blogsData, setBlogsData] = useState([]);
  useEffect(() => {
    getBlogs();
  }, []);

  const getBlogs = async () => {
    try {
      const blogDataResponse = await getOnlineUsers();
      const newBlogsData = blogDataResponse[0].blogs;
      const rows = Object.keys(newBlogsData).map(key => newBlogsData[key]);
      const filteredUser = rows.find(user => user.user === getUser);
      
      if (filteredUser) {
        setBlogsData([filteredUser]);
      } else {
        console.log('User not found');
      }
    } catch (error) {
      console.error('Error fetching blogs:', error);
      throw new Error('Error fetching blogs');
    }
  };
  return (
    <>
      {blogsData.map((blogInfo, index) => (
            <Card key={index} style={{maxWidth:"33rem",float:"right",marginRight:"0.5rem",marginLeft:"0.5rem",marginTop:"1rem"}}>
              <CardMedia
                component="img"
                alt=""
                style={{ objectFit: 'cover', height: '15rem' }}
                image={blogInfo.imgLink1}
              />
              <Avatar
                alt="Remy Sharp"
                src={blogInfo.imgLink0}
                sx={{ width: 140, height: 140, top: '-4.5rem', marginLeft: '2rem', border: '0.5rem solid white' }}
              />
              <CardContent item mt={5} ml={2}>
                <Typography mt={-18.5} sx={{ float: 'right' }} variant="body1" color="textSecondary">
                  {blogInfo.location}
                </Typography>
                <Typography mt={-11.5} sx={{ marginLeft: '3rem' }} variant="body1" color="textSecondary">
                  {blogInfo.user}
                </Typography>
                <Typography mt={2} gutterBottom variant="h4" component="div">
                  {blogInfo.blogTitle}
                </Typography>
                <Typography mt={1} variant="h6" color="textSecondary">
                  Introduction
                </Typography>
                <Typography mt={1} variant="h7" color="textSecondary">
                  {blogInfo.introduction}
                </Typography>
                <CardMedia
                  component="img"
                  alt="Blog Picture here"
                  style={{ objectFit: 'cover', height: '28rem', marginTop: '2rem', marginBottom: '1.8rem' }}
                  image={blogInfo.imgLink2}
                />
                <Typography mt={8} sx={{ marginBottom: '2.8rem' }} variant="h7" color="textSecondary">
                  {blogInfo.main}
                </Typography>
                <CardMedia
                  component="img"
                  alt="Blog Picture here"
                  style={{ objectFit: 'cover', height: '28rem', marginBottom: '1.8rem', marginTop: '1.8rem' }}
                  image={blogInfo.imgLink3}
                />
                <Typography mt={1} variant="h7" sx={{ marginBottom: '1.8rem' }} color="textSecondary">
                  {blogInfo.end}
                </Typography>
                <Typography mt={2} variant="body2" color="textSecondary">
                  {blogInfo.contact}
                </Typography>
              </CardContent>
              <Button style={{ float: 'left', marginTop: '0.5rem', marginRight: '0.5rem' }}
                variant="text"
                size="small"
                color="primary"
                href="https://www.localproject.app/Blogs"
                rel={`noopener noreferrer tag ${blogInfo.tags}`}
              >
                Tags {blogInfo.tags}
              </Button>
              <CardActions style={{ float: 'right' }}>
                <Button size="small" color="primary" href={`https://www.localproject.app/UserProjects/${blogInfo.user}`}>
                  Creator
                </Button>
                <Button size="small" color="primary" href={blogInfo.link}>
                  Social Link
                </Button>
              </CardActions>
            </Card>
         
      ))}
    </>
  );
};
export default UserBlogs