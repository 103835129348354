import errorImg from '../images/error.webp'
import bugFixImg from '../images/BugFixes.webp'
import freeSignUp from '../images/FreeSignUp.webp'
import getInvolved from '../images/getInvolved.webp'
import donate from '../images/donate.webp'
import support from '../images/support.webp'
import motivation from '../images/motivation.webp'
import ideas from '../images/ideas.webp'
import upload from '../images/uploadPhotos.webp'
import addEvents from '../images/addEvents.webp'
import chat from '../images/chat.webp'
import tasks from '../images/tasks.webp'
import ManageTasks from '../images/ManageTasks.webp'

export const cards = [
  {
    title: "User Tasks - 27.09.2023",
    description: "Implemented the capability to add personal tasks and ensured that it automatically updated when new tasks are added.", 
    image: ManageTasks,
    extra: ""
  },
   {
    title: "More Fixes - 19.09.2023",
    description: "Fixed many errors and reworked Cors on ServerSide + Android login is currently down.",
    image: errorImg,
    extra: ""
  },
  {
    title: "Fixed Bugs - 18.09.2023",
    description: "Fixed join project button and remove project members + new Volunteer button added.",
    image: bugFixImg,
    extra: ""
  },
  {
    title: "Free Sign Up",
    description: "Do you have a local project you want to share, or are you looking to help others? You can sign up for free and enjoy the benefits of searching locally and creating new projects.",
    image: freeSignUp,
    extra: ""
  },
  {
    title: "Get Involved",
    description: "Are you interested in making a difference in your community? We have a variety of volunteer opportunities available for you to get involved with. Whether you want to work directly with people or help behind the scenes, there is something for everyone.",
    image: getInvolved,
    extra: ""
  },
  {
    title: "Donate Today",
    description: "Your support can make a big difference in the lives of those in need. Whether you can donate time, money, or resources, we appreciate any contribution you can make to our cause. Donating can make a huge differnce and help others in need for a great project. ",
    image: donate,
    extra: ""
  },
  {
    title: "Supporting and empowering others",
    description: "Supporting and empowering others is a transformative act that can make a significant difference in their lives. By actively listening, validating feelings, offering encouragement, providing practical assistance, practicing empathy, fostering a positive environment, being a role model, and promoting empowerment, you can uplift and inspire those around you. Remember, your support has the potential to unlock their true potential and contribute to their overall well-being and success.",
    image: support,
    extra: ""
  },
  {
    title: "Finding Motivation",
    description: "Finding motivation is a personal journey that requires self-reflection, goal-setting, and creating a supportive environment. By defining your goals, connecting with your why, breaking tasks down, seeking inspiration, visualizing success, practicing self-care, and maintaining persistence, you can reignite your motivation and unlock your full potential. Embrace the power within you and embark on a journey of growth and achievement.",
    image: motivation,
    extra: ""
  },
  {
    title: "How to Find Ideas for Your Next Project",
    description: "Finding ideas is a dynamic and personal process that requires openness, curiosity, and a willingness to explore. By embracing curiosity, engaging in brainstorming, stepping out of your comfort zone, seeking inspiration from diverse sources, keeping a journal, using mind mapping, collaborating with others, and embracing solitude, you can unlock the limitless possibilities of your creativity. Trust in your ability to discover unique and innovative ideas that will propel your projects to new heights.",
    image: ideas,
    extra: ""
  }
]

export const components = [
  {
    title: "Add Events",
    description: "With our new calander you can now add social events and share between the cloud data base App.",
    image: addEvents,
    extra: ""
  },
  {
    title: "Share your best Images or Photos",
    description: "You can also share your photos with the relevant Project.",
    image: upload,
    extra: ""
  },
  {
    title: "Chit Chat",
    description: "Chat with new friends.",
    image: chat,
    extra: ""
  },
  {
    title: "Share Tasks",
    description: "You can also share tasks with members of your project.",
    image: tasks,
    extra: ""
  }
]

export const reviews = [
  {
    name: "Alex",
    title: "It's an easy-to-use platform that connects me with my  local community.",
    image: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80"
  },
  {
    name: "Luis",
    title: "Great website for finding new friends.",
    image: "https://images.unsplash.com/photo-1544725176-7c40e5a71c5e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1167&q=80"
  },
  {
    name: "Susan",
    title: "Created a project got funding found new friends.",
    image: "https://images.unsplash.com/photo-1523265093867-1ba6b30f685b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80"
  },
];

 export const productss = [
    {
     title: "Bloomify® Premium Garden Tool",
     description: "Introducing the Bloomify Tool Set – where gardening meets innovation! Say hello to your new trio of essential garden companions, meticulously designed to elevate your gardening game to a whole new level. With these tools in hand, you're not just equipped; you're empowered! Whether you're digging, raking, or hoeing, you'll tackle your gardening tasks with effortless precision. But that's not all – these tools are not your ordinary garden implements. They come packed with a range of additional features that will pleasantly surprise you. Innovation meets practicality in every aspect. After a rewarding day of gardening, you can neatly stow away your Bloomify tools in the included storage pouch. Or, if you prefer a touch of flair, hang them by their convenient straps, instantly adding a touch of garden-chic to your space. Bloomify Werkzeugset – because your garden deserves the best, and so do you. Elevate your gardening experience today!",
     image: "https://m.media-amazon.com/images/I/51I5wKTXx4L._AC_SL1356_.jpg",
     link: "https://www.amazon.de/NEU-Gartenwerkzeuge-Set-hochwertigen-Gartenger%25C3%25A4te-Garten-Schaufel/dp/B0B7XWQYCG/ref=sr_1_16?__mk_de_DE=%25C3%2585M%25C3%2585%25C5%25BD%25C3%2595%25C3%2591&amp;crid=1NILRHIP5MCB4&amp;keywords=Garden+Tool&amp;qid=1695571883&amp;s=garden&amp;sprefix=garden+tool%252Coutdoor%252C100&amp;sr=1-16&_encoding=UTF8&tag=philgames-21&linkCode=ur2&linkId=4e2710bfa17a615a4e701bcc3c829bf2&camp=1638&creative=6742"
   },
    {
     title: "Gardena Garden Shears",
     description:"Gardena garden shears offer both power and quick cutting and make cutting in your garden a simple and comfortable job. Among the various classic, comfort and premium models from Gardena you will find the ideal tool for everyone - from the beginner to the professional user.",
     image:"https://m.media-amazon.com/images/I/41UZADATR5L._AC_SL1000_.jpg",
     link: "https://www.amazon.de/GARDENA-Gartenschere-Pflanzenschonende-Bypass-Schneide-Schneid-Durchmesser/dp/B01FE8M0WI/ref=sr_1_47?__mk_de_DE=%25C3%2585M%25C3%2585%25C5%25BD%25C3%2595%25C3%2591&crid=2WZR4WJTOXQL1&keywords=Gardening%252Btools&qid=1695574528&sprefix=gardening%252Btool%252Caps%252C105&sr=8-47&th=1&_encoding=UTF8&tag=philgames-21&linkCode=ur2&linkId=4c0c824c7df41c1a2764b4e1b7fc2019&camp=1638&creative=6742"
    }
 ]
