import React, { useEffect, useState } from 'react';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getCalandar } from '../services/InboxService';
import { getDayWithSuffix } from './CalanderLogic';
import Noti from './Noti'; // Import the 'Noti' component
import Remi from './Remi'; // Import the 'Remi' component
import Divider from '@mui/material/Divider';

const CalendarOnlyData = ({ realUser }) => {
  const now = new Date();
  const [currentMonth, setCurrentMonth] = useState(now.getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(now.getFullYear());
  const [monthNamen, setMonthNamen] = useState(now.toLocaleString('default', { month: 'long' }));
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [date, setDate] = useState(new Date());
  const [calandar, setCalandar] = useState([]);

  useEffect(() => {
    const data = { user: realUser };
    getCalandar(data).then(inboxes => {
      const calandarInfo = inboxes[0].calander;
      setCurrentMonth(currentMonth);
      setCalandar(calandarInfo);
    });

  }, [currentMonth, realUser]);

  const handleDialogOpen = (day) => {
    setIsDialogOpen(true);
    setIsDialogOpen(true);
    setDate(new Date(currentYear, currentMonth - 1, day + 1));
  };

  const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getMonthDays = (month, year) => {
    const daysInMonth = getDaysInMonth(month, year);
    return Array.from({ length: daysInMonth }, (_, i) => i + 1);
  };


  const monthDays = getMonthDays(currentMonth, currentYear);
  let daytime;

  return (
    <Grid container spacing={1} style={{ marginBottom: '0.5rem', marginLeft: '0.5rem' }}>
  {monthDays.map((day) => {
    // Filter calendar entries for the current day
    const dayEntries = calandar.filter((newData) =>
      new Date(newData.date).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }) === day + ' ' + monthNamen + ' ' + currentYear
    );

    if (dayEntries.length > 0) {
      return (
        <Grid  style={{justifyContent: "center", marginLeft: "auto", marginRight: "auto"}} item key={day}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              onClick={() => handleDialogOpen(day)}
            >
              <Typography variant="body1">
                {getDayWithSuffix(day)} {monthNamen}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div hidden>
                {(daytime = day + ' ' + monthNamen + ' ' + currentYear)}
              </div>
              {dayEntries.map((newData) => (
                <div key={newData.date}>
                  <Typography variant='body1' color="textSecondary">
                    {newData.title}
                  </Typography>
                  <Noti date={newData.date} message={newData.title} />
                  <Remi date={newData.date} message={newData.title} />
                  <Divider></Divider>
                  </div>
                  
                  )
                )}
            </AccordionDetails>
          </Accordion>
        </Grid>
      );
    } else {
      return null; // Skip rendering if there are no entries for the day
    }
  })}
</Grid>
  );
};

export default CalendarOnlyData;
