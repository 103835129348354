export async function createNewUser(data) {
  const response = await fetch(`https://localprojectapi-api.vercel.app/api/createNewUser`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json',
    //add Website
    'Access-Control-Allow-Origin': 'http://localproject.app' },
    body: JSON.stringify({ user: data })
})
return await response.json();
}

export async function loginGoogle(token) {
  const response = await fetch(`https://localprojectapi-api.vercel.app/api/loginGoogle`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({ user: token })
  });
  return await response.json();
}

export async function updateUserPassword(data) {
  const response = await fetch(`https://localprojectapi-api.vercel.app/api/updateUserPassword`, {
    method: 'PUT', 
    headers: { 'Content-Type': 'application/json',
    //add Website
    'Access-Control-Allow-Origin': 'http://localproject.app' },
    body: JSON.stringify({ user: data })
})
return await response.json();
}

export async function getLogin(data, john, apiKey) {
  await fetch(`https://localprojectapi-api.vercel.app/api/getLogin`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json'},
    body: JSON.stringify({ user: data })
  }).then(
    response => response.json()
      .then(
        // build function for success create nav
        success => john.push(success))// Handle the success response object
  ).catch(
    error => console.log(error) // Handle the error response object
  );
  return john;
};

export async function getUserEmail(data, john) {
  await fetch(`https://localprojectapi-api.vercel.app/api/getUserEmail`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' ,
    'Access-Control-Allow-Origin': 'http://localproject.app' },
    body: JSON.stringify({ user: data })
  }).then(
    response => response.json()
      .then(
        // build function for success create nav
        success => john.push(success))// Handle the success response object
  ).catch(
    error => console.log(error) // Handle the error response object
  );
  return john;
};

export async function getKey(newData, theKey) {
  await fetch(`https://localprojectapi-api.vercel.app/api/getKey`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'http://localproject.app' },
    body: JSON.stringify({ user: newData })
  }).then(
    response => response.json()
      .then(
        success => theKey.push(success))
  ).catch(
    error => console.log(error)
  );
  return theKey;
};

export async function getKeys(newData, theKey) {
  await fetch(`https://localprojectapi-api.vercel.app/api/getKeys`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'http://localproject.app' },
    body: JSON.stringify({ user: newData })
  }).then(
    response => response.json()
      .then(
        success => theKey.push(success))
  ).catch(
    error => console.log(error)
  );
  return theKey;
};

export async function createKey(data) {
  const response = await fetch(`https://localprojectapi-api.vercel.app/api/createKey`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'http://localproject.app' },
    body: JSON.stringify({ user: data })
  })
  return await response.json();
}

export async function getUsers() {
  const response = await fetch('https://localprojectapi-api.vercel.app/api/users');
  return await response.json();
}

export async function getCalandar() {
  const response = await fetch('https://localprojectapi-api.vercel.app/api/getCalandar');
  return await response.json();
}

export async function getLocation(data) {
  const response = await fetch(`https://nominatim.openstreetmap.org/search.php?q=${data}&polygon_geojson=1&format=jsonv2`);
  return await response.json();
}

export async function fetchSettings() {
  const response = await fetch('/api/settings');
  return await response.json();
}