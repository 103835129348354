import React, { useState, useEffect } from 'react'
import Footer from '../components/Footer'
import RecentUsers from '../components/RecentUsers'
import LoginBarTop from '../components/LoginBarTop'
import UserTasks from '../components/UserTasks'
import CalendarOnlyData from '../components/CalendarOnlyData'
import UserProjects from '../components/UserProjects'
import CheckInbox from '../components/CheckInbox'
import UserBlogs from '../components/UserBlogs'
import Twitters from '../components/Twitters'
import { useLocation, useNavigate } from 'react-router-dom'
import { Grid } from '@material-ui/core';


const Home = () => {
  const nav = useNavigate()
  const location = useLocation()
  const realUser = location.state?.realUser
  if (!realUser) {
    nav('/');
  }
  const removeData = 1;
  const [isLargeScreen, setIsLargeScreen] = useState(true);
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 600); // Adjust the width as needed
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <LoginBarTop realUser={realUser} />
      <Grid container item xs={12} marginRight={1} spacing={1}>
        <RecentUsers realUser={realUser} />
        {isLargeScreen && <CalendarOnlyData realUser={realUser} />}
        <Twitters realUser={realUser}></Twitters>
        <CheckInbox removeData={removeData} realUser={realUser} />
        <UserProjects removeData={removeData} otherUser={realUser} />
        <UserTasks removeData={removeData} realUser={realUser} />
      </Grid>
      <Footer />
    </>
  );
};
export default Home