import React, { createContext, useState } from 'react';

const TaskContext = createContext();

const TaskProvider = ({ children }) => {
  const [tasks, setTasks] = useState([
    {  },
   
    // ... other tasks
  ]);

  const updatePdfStatus = (taskId, pdfUploaded) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task.id === taskId ? { ...task, pdfUploaded } : task
      )
    );
  };

  return (
    <TaskContext.Provider value={{ tasks, updatePdfStatus }}>
      {children}
    </TaskContext.Provider>
  );
};

export  { TaskContext, TaskProvider };