import React from 'react';
import { Button } from '@material-ui/core';
function Remi({date,message}) {
  const setReminder = () => {
    if ('Notification' in window && Notification.permission === 'granted') {
      const notification = new Notification('Reminder', {
        body: message + date,
      });

      notification.onclick = () => {
        // Handle click event (e.g., redirect to a specific page).
        console.log('User clicked on the notification');
      };
    } else {
      console.log('Notification permission denied');
    }
  };

  return (
    <div>
      <Button variant="text" onClick={setReminder}>Set Reminder</Button>
    </div>
  );
}

export default Remi;