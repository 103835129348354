import React, { } from 'react';
import Blog from '../homePage/HomePage';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea ,Card} from '@mui/material';
import { useNavigate } from 'react-router-dom';
const AboutUs = () => {
    const nav = useNavigate()
    const Home = () => {
        nav('/')
    }
    return (
        <>
            <Blog></Blog>
            <div className="subContainer" sx={{ maxWidth: "350px", justifyContent: "center", marginLeft: "auto", marginRight: "auto",marginBottom:"350px", marginTop:"3rem"}}>
                <Card sx={{ maxWidth: 620, minWidth: 300, width: "5rem" }}>
                    <CardActionArea onClick={Home}>
                        <div className="subTitle"></div>
                        <CardMedia
                            component="img"
                            height="140"
                            image="https://www.dreamhost.com/academy/wp-content/uploads/2020/12/started_1-website-building-100-750x498.jpg"
                            alt="Volunteering"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                About Us
                            </Typography>
                            <div style={{ height: '550px', overflowY: 'scroll' }}>
                                <Typography variant="body2" color="text.secondary">
                                Hello everyone!<br /><br />

I am thrilled to share with you all about my website, www.localproject.app. This is a project that is very close to my heart, and it has been an exciting journey for me to develop it using some of the latest technologies such as React, MongoDB, and Express.<br /><br />

Let me start by saying that this website is still in development, and we have encountered many challenges along the way. However, with every challenge, we have grown, and we have come out stronger and better equipped to tackle the next one.<br /><br />

One of the most exciting things about this project is the use of React. It has allowed us to create dynamic and interactive user interfaces that are both beautiful and functional. We have also used MongoDB, which has given us the flexibility to store and retrieve data in a way that makes sense for our website. Finally, Express has allowed us to build a robust backend that can handle all of the website's business logic and processing.<br /><br />

Of course, there have been challenges along the way. Developing a website is never easy, and when you're working with cutting-edge technologies, there's always a learning curve. However, we have persevered, and we have learned so much in the process.<br /><br />

Looking ahead, I am excited to see where this project will go. We have big plans for the future, and we are confident that we can continue to build on the success we've had so far. We are dedicated to making this website the best it can be, and we can't wait to share it with the world.<br /><br />

So, if you're looking for a fun and exciting project to follow, look no further than www.localproject.app. We're just getting started, and we would love for you to be a part of this journey with us. Thank you for your support, and we can't wait to see where this project takes us!
<meta name="description" content="Learn about the Local Project website and its journey in using React, MongoDB, and Express for development. Discover the challenges and successes of the project." />
<meta name="keywords" content="Local Project, website development, React, MongoDB, Express, challenges, successes" />
<meta name="author" content="Phil" />
                                </Typography>
                            </div>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </div>
        </>
    )
}

export default AboutUs;
