export async function getOnlineUsers() {
    const headers = {
        'Content-Type': 'application/json', // Adjust the content type based on your API's requirements
        // Add any other headers you need
    };
    try {
        const response = await fetch('https://localprojectapi-api.vercel.app/api/onlines', {
            method: 'GET',    
            headers: headers,
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching online users:', error);
        throw new Error('Failed to fetch online users.');
    }
}

export async function createOnline(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/online`, {
        method: 'POST', 
        body: JSON.stringify({ online: data })
    })
    return await response.json();
}

export async function isOffline(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/isOffline`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ online: data })
    })
    return await response.json();
}

export async function fetchSettings() {
    const response = await fetch('https://localprojectapi-api.vercel.app/api/settings');
    return await response.json();
}

export async function updateOnlineImage(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/updateOnlineImage`, {
        method: 'PUT', 
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ online: data })
    })
    return await response.json();
}

export async function isOnline(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/isOnline`, {
        method: 'PUT',   
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ online: data })
    })
    return await response.json();
}

export async function createJob(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/createJob`, {
        method: 'PUT',    
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({online: data})
    })
    return await response.json();
}

export async function createBlog(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/createBlog`, {
        method: 'PUT',    
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({online: data})
    })
    return await response.json();
}

export async function createTweet(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/createTweet`, {
        method: 'PUT',    
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({online: data})
    })
    return await response.json();
}