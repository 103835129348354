import React, {useEffect, useState } from 'react'
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CardActions from '@mui/material/CardActions'
import CardMedia from '@mui/material/CardMedia'
import Box from '@mui/material/Box'
import { Grid } from '@mui/material'
import { createBlog } from '../services/OnlineService'
import Swal, { } from 'sweetalert2/dist/sweetalert2.js'

const AddBlogForm = ({ onClose, buttonImg, realUser, imgLinkZero, imgLinkOne, imgLinkTwo, imgLinkThree  }) => {
  const [tags, setTags] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [blogPosted, setBlogPosted] = useState(false);

  const [formData, setFormData] = useState({
    user: realUser,
    avatar:imgLinkZero,
    blogTitle: '',
    location: '',
    introduction: '',
    main: '',
    end: '',
    contact: '',
    tags: tags,
    imgLink0:imgLinkZero,
    imgLink1:imgLinkOne,
    imgLink2:imgLinkTwo,
    imgLink3:imgLinkThree
  });
  
  const handleTagsChange = (e) => {
    const inputValue = e.target.value;
    if (tags.length < 3 && inputValue.trim() !== '') {
      setTags([...tags, inputValue.trim()]);
      setInputValue(''); // Clear the input field
    }
    const tagsArray = inputValue.split(' ').slice(0, 3);
    const limitedTags = tagsArray.join(' ');
    setTags(limitedTags);
    handleChange(e)
  };

  const handleChange = ((event) => {

    if(imgLinkZero){
      formData['imgLink0'] = imgLinkZero
    }
    if(imgLinkOne){
      formData['imgLink1'] = imgLinkOne
    }
    if(imgLinkTwo){
      formData['imgLink2'] = imgLinkTwo
    }
    if(imgLinkThree){
      formData['imgLink3'] = imgLinkThree
    }
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  });

  useEffect(()=>{
    if(imgLinkZero){
      formData['imgLink0'] = imgLinkZero
    }
    if(imgLinkOne){
      formData['imgLink1'] = imgLinkOne
    }
    if(imgLinkTwo){
      formData['imgLink2'] = imgLinkTwo
    }
    if(imgLinkThree){
      formData['imgLink3'] = imgLinkThree
    }
    setFormData({
      ...formData,
    });
  },[buttonImg, tags,formData, imgLinkOne, imgLinkThree, imgLinkTwo, imgLinkZero])

  const handleSubmit = async (event) => {
    formData['imgLink0'] = imgLinkZero
    formData['imgLink1'] = imgLinkOne
    formData['imgLink2'] = imgLinkTwo
    formData['imgLink3'] = imgLinkThree
    // Call the function to create a job
    await createBlog(formData);
    setBlogPosted(true);
    Swal.fire("Blog Posted Great Job!")
  };

  return (
    <div >
      <Grid container spacing={1}>
        <Grid item xs={12} mt={1} ml={2} md={4} >
          <Card>
            <CardMedia
              component="Img"
              alt="Top Image"
              style={{ objectFit:"cover", height: '15rem' }}
              image={formData.imgLink1} //  imgLink is the image URL
            />
             <Avatar
                alt="Remy Sharp"
                src={formData.imgLink0}
                sx={{ width: 140, height:140 ,top:"-4.5rem",marginLeft:"2rem", border: "0.5rem solid white" }}
              />
            <CardContent item mt={5} ml={2}>
            <Typography mt={-18.5} sx={{float:"right"}} variant="body1" color="textSecondary">
               {formData.location}
              </Typography>
              <Typography mt={-11.5} sx={{marginLeft:"3rem"}}  variant="body1" color="textSecondary">
               {formData.user}
              </Typography>
              <Typography mt={2} gutterBottom variant="h4" component="div">
                {formData.blogTitle}
              </Typography>
              <Typography mt={1} variant="h6" color="textSecondary">
              Introducton
              </Typography>
              <Typography mt={1} variant="h7"  color="textSecondary">
               {formData.introduction}
              </Typography>
              <CardMedia
                component="Img"
                alt="Middle Image"
                style={{ objectFit:"cover", height: '28rem',marginTop:"2rem",marginBottom:"1.8rem" }}
                image={formData.imgLink2} // Assuming imgLink is the image URL
              />
              <Typography mt={8} sx={{marginBottom:"2.8rem"}} variant="h7" color="textSecondary">
                {formData.main}
              </Typography>
              <CardMedia
                component="Img"
                alt="Bottom Image"
                style={{ objectFit:"cover",height: '28rem',marginBottom:"1.8rem",marginTop:"1.8rem"}}
                image={formData.imgLink3} // Assuming imgLink is the image URL
              />
              <Typography mt={1} variant="h7"  sx={{marginBottom:"1.8rem"}} color="textSecondary">
                {formData.end}
              </Typography>
              <Typography mt={2} variant="body2" color="textSecondary">
                {formData.contact}
              </Typography>
            </CardContent>
            <Button style={{float:"left",marginTop:"0.5rem",marginRight:"0.5rem"}}
                  variant="text"
                  size="small"
                  color="primary"
                  href="https://www.localproject.app/Blogs"
                  rel={`noopener noreferrer tag ${formData.tags}`}
                >
                Tags {formData.tags}
                </Button>
            <CardActions style={{float:"right"}}>   
              <Button  size="small" color="primary" href={`https://www.localproject.app/UserProjects/${formData.user}`}>
                  Creator
                </Button>
                <Button size="small" color="primary" href={formData.link}>
                  Social Link
                </Button>
              </CardActions>
          </Card>
        </Grid>
        <Grid item mt={5} xs={12} md={4} >
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          />
          <Button onClick={onClose}>Close Form</Button>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Blog Title"
              variant="outlined"
              inputProps={{ maxLength: 50 }}
              margin="normal"
              required
              name="blogTitle"
              value={formData.blogTitle}
              onChange={handleChange}
            />

            <TextField
              label="Location & Time"
              variant="outlined"
              inputProps={{ maxLength: 50 }}
              margin="normal"
              sx={{
                marginLeft: '10px', // Adjust the value as needed
              }}
              required
              name="location"
              value={formData.location}
              onChange={handleChange}
            />

            <TextField
              label="Write an Introduction"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              inputProps={{ maxLength: 1500 }}
              name="introduction"
              value={formData.introduction}
              onChange={handleChange}
              multiline
              rows={3}
            />

            <TextField
              label="Main Information"
              variant="outlined"
              margin="normal"
              required
              inputProps={{ maxLength: 1500 }}
              fullWidth
              sx={{
                // Adjust the value as needed
              }}
              name="main"
              value={formData.main}
              onChange={handleChange}
              multiline
              rows={8}
            />

            <TextField
              label="End Part"
              variant="outlined"
              margin="normal"
              required
              multiline
              inputProps={{ maxLength: 1500 }}
              fullWidth
              rows={5}
              name="end"
              value={formData.end}
              onChange={handleChange}
            />

            <TextField
              label="Blog Writter"
              variant="outlined"
              inputProps={{ maxLength: 30 }}
              margin="normal"
              required
              name="contact"
              value={formData.contact}
              onChange={handleChange}
            />
            <TextField
              sx={{marginLeft:"1rem"}}
              label="3 Tags"
              variant="outlined"
              margin="normal"
              required
              name="tags"
              value={tags}
              inputProps={{ maxLength: 30 }}
              onChange={handleTagsChange}
            />
            <TextField
              inputProps={{ maxLength: 100 }}
              label="Social Link"
              variant="outlined"
              margin="normal"
              required
              name="link"
              value={formData.link}
              onChange={handleChange}
            />
            <Button style={{ float: "right", marginRight: "1rem", bottom: "100px",marginTop:"7.5rem"}} type="submit" variant="contained" color="primary">
              Post Blog
            </Button>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};
export default AddBlogForm;