import React from "react";
import { useEffect } from "react";
import Routing from './Routing';
export default function App() {
  useEffect(() => {
    document.cookie = 'cookie_name=_ga_TGS5K5B08V; SameSite=None; Secure';
  }, []);
  return (
    <>
<Routing/>
    </>
  );
}