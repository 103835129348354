import Swal from 'sweetalert2';
const imgUser = [];
const resizeImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve) => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = () => {
            let width = img.width;
            let height = img.height;
            if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
            }
            if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
            }
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);
            canvas.toBlob((blob) => {
                const resizedFile = new File([blob], file.name, {
                    type: file.type,
                    lastModified: file.lastModified,
                });
                resolve(resizedFile);
            }, file.type, 0.8);
        };
        img.src = URL.createObjectURL(file);
    });
};

export const onImageChange = (selectedImage, realUser, setImage) => {
    return new Promise(async (resolve, reject) => {
        try {
            imgUser.push(realUser);
            const files = selectedImage;
            if (files.size > 4194304) {
                Swal.fire("5mb Limit");
                reject("Image size exceeds the limit");
            } else {
                const formData = new FormData();
                for (let i = 0; i < files.size; i++) {
                    const resizedFile = await resizeImage(files, 500, 400);
                    formData.append("file", resizedFile);
                    formData.append("filename", resizedFile.name);
                    formData.append("type", resizedFile.type);
                    formData.append("size", resizedFile.size);
                    formData.append("lastModified", resizedFile.lastModified);
                    formData.append("title", "");
                    formData.append("description", "");
                    formData.append("projectId", "");
                    formData.append("metadata", "metadata");
                    break
                }
                await upload(formData, setImage);
                resolve("Image Sent to Update");     
            }
        } catch (error) {
            reject(error);
        }
    });
}

const upload = async (formData, setImage) => {
    try {
        const response = await fetch('https://localprojectapi-api.vercel.app/file/upload', {
            method: 'POST',
            headers: {},
            body: formData
        });
        if (response.ok) {
            const success = await response.json();
            setImage(success.imgUrl);
        } else {
            console.log('Error uploading the image');
        }
    } catch (error) {
        console.error('Network error:', error);
    }
};