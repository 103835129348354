import React, { useState, useEffect } from 'react'
import FormControl from '@mui/material/FormControl'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import CardContent from '@mui/material/CardContent'
import { Button } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { getUserImages } from '../services/InboxService'
const Swal = require('sweetalert2')

const UploadImg = ({realUser}) => {
    const newLocation = useLocation()
    const state = newLocation.state | "";
    const user = state.user
    const [personName, setPersonName] = useState([])
    const john = "David"
    const [userImages, setUserImages] = useState([])
    const [open, setOpen] = useState(false)
    const [newImage, setNewImage] = useState("")
    const [imgDelete, setImgDelete] = useState(false)
 
    const [checked, setChecked] = React.useState(false)
    const handleClose = () => {
        setOpen(false)
    };
    const handleToggle = () => {
        setOpen(!open)
    };
    useEffect(() => {
        window.onbeforeunload = function (e) {
            { return false; };
        };
        const data = { user: user }
        getUserImages(data).then(response => {
            setUserImages(response)
        });
    }, [user, newImage, imgDelete]);
    
    function uploadLinkImg(success) {
        setNewImage(success.imgUrl)     
        setOpen(false)    
    }
    const resizeImage = (file, maxWidth, maxHeight) => {
        return new Promise((resolve) => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            const img = new Image()
            img.onload = () => {
                let width = img.width;
                let height = img.height;
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
                canvas.width = width
                canvas.height = height
                ctx.drawImage(img, 0, 0, width, height);
                canvas.toBlob((blob) => {
                    const resizedFile = new File([blob], file.name, {
                        type: file.type,
                        lastModified: file.lastModified,
                    });
                    resolve(resizedFile)
                }, file.type, 0.8);
            };
            img.src = URL.createObjectURL(file)
        });
    };
    const maxSizeInBytes = 5 * 1024 * 1024;

    const onImageChange = async (e) => {  
        e.preventDefault();
        const files = document.getElementById("file")
        if (files.files[0].size > maxSizeInBytes) {
            Swal.fire("5mb Limit");
        } else {
            const formData = new FormData();
            for (let i = 0; i < files.files.length; i++) {
                const resizedFile = await resizeImage(files.files[0], 1000, 1000)
                formData.append("file", resizedFile)
                formData.append("filename", resizedFile.name)
                formData.append("id", resizedFile.id)
                formData.append("type", resizedFile.type)
                formData.append("size", resizedFile.size)
                formData.append("lastModified", resizedFile.lastModified)
                formData.append("metadata", "metadata")
                formData.append("projectId", personName)
                upload(formData)
            }
        }
    };
    const upload = async (formData) => {
        await fetch(`https://localprojectapi-api.vercel.app/file/upload`, {
            // Your POST endpoint
            method: 'POST',
            header: {},
            body: formData// This is your file object
        }).then(
            response => response.json()
                .then(
                    success => uploadLinkImg(success))// Handle the success response object
        ).catch(
            error => console.log(error) // Handle the error response object
        );
    };
    const handleChanges = () => {
        setChecked((prev) => !prev)
    }
    return (
        <><>
       {(user !== "Guest") && <div key={12} onBeforeInput={handleChanges} style={{maxWidth:"240px"}}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                    onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <>
                    <CardContent sx={{ flexGrow: 1 }}>   
                        <FormControl sx={{ m: 1, marginBottom: "5px", zIndex: 1000 }}>
                            <label htmlFor='metadata' id='metadata' type="metadata" value={john} onChange={onImageChange} hidden>Your name</label>
                            
                            <img  style={{marginRight:"4rem", marginTop: "5px",height:"30rem",minWidth:"28rem",objectFit:"cover" }} className="is-rounded" src={newImage} id="mainImg" alt="" />
                            <Button sx={{maxWidth:"28rem",marginTop:"1rem"}} variant='outlined'>
                                <input className="file-input" onClick={handleToggle} placeholder='COck' alt="Cock" htmlFor='file' id='file' type="file" multiple accept='image/*' encType='multipart/form-data' onChange={onImageChange} />
                                Load Image
                            </Button>
                        </FormControl>
                    </CardContent>
                </>
            </div>}
        </></>
    )
}
export default UploadImg;