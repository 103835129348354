import React, { useState, useEffect } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Footer from './Footer';
import LoginBarTop from './LoginBarTop';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import Typography from '@material-ui/core/Typography';
import { useNavigate, useLocation } from 'react-router-dom';
import { getInbox, addToProjects, removeMember } from '../services/InboxService';
import { Button } from '@mui/material';

const MemberRequest = () => {
  const nav = useNavigate();
  const newLocation = useLocation();
  const state = newLocation.state;
  const user = state.realUser;
  const realUser = state.realUser;
  if (realUser === null) {
    nav('/');
  }
  const [members, setMembers] = useState([]);
  const [isMember, setIsMember] = useState([]);
  const [gotMembers, setGotMembers] = useState([]);
  const [refresh, setRefresh] = useState(false);

  // Function to fetch inbox data
  const fetchInboxData = async (userData) => {
    try {
      const inboxData = await getInbox(userData);
      return inboxData || [];
    } catch (error) {
      console.error('Error fetching inbox data:', error);
      return [];
    }
  };

  useEffect(() => {
    // Fetch member requests
    const fetchUserRequests = async () => {
      const inboxData = await fetchInboxData({ user });
      const memberRequests = inboxData[0]?.members || [];
      const newMembers = memberRequests.filter((request) => request.isMember === 0);
      setMembers(newMembers);
    };

    // Fetch members
    const fetchMembers = async () => {
      const inboxData = await fetchInboxData({ user });
      const members = inboxData[0]?.members || [];
      const newIsMembers = members.filter((member) => member.isMember === 1);
      setIsMember(newIsMembers);
    };

    fetchUserRequests();
    fetchMembers();
  }, [gotMembers, user, refresh]);

  const removeMemberProject = (e) => {
    const _id = e.currentTarget.id;
    const name = e.currentTarget.value;
    const data = { user: realUser, _id };
    Swal.fire({
      title: 'Delete ' + name + ' Member?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Delete',
      denyButtonText: `Don't Delete`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Deleted! ' + name + ' success');
        removeMember(data);
        setRefresh(true);
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
    });
  };

  function addToProject(e) {
    const id = e.target.value;
    const member = e.target.name;
    const isMember = 1;
    const data = { id, isMember: isMember, user: member, project: id };
    addToProjects(data);
    setGotMembers(data);
  }

  const isUserProfile = (e) => {
    const user = e.currentTarget.id;
    nav(`/UserProjects`, { state: { user, realUser } });
  };

  return (
    <>
      <LoginBarTop realUser={realUser}></LoginBarTop>
      <Grid
        id="photoBlog"
        style={{ justifyContent: 'center' }}
        container
        spacing={1}
        mt={4}
      >
        <Grid
          item xs={4} sx={{ minWidth: "400px" }}
        >
          <Card>
            <CardMedia
              component="img"
              image={""}
              alt="Volunteer Img brb"
              sx={{
                height: 3,
                width: '100%',
                objectFit: 'cover',
              }} />
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography style={{ marginTop: "20px" }}>Requests</Typography>
              <Card style={{ marginTop: "30px" }}>
                {members.map((item, index) => {
                  return (
                    <div style={{ marginLeft: "10px", marginTop: "10px" }} key={index}>
                      <ul value={item.project} key={index}>
                        {item.member} wants to help {item.name} &nbsp; &nbsp;
                        <Button
                          value={item.project}
                          name={item.name}
                          onClick={addToProject}
                          className="button is-primary is-light"
                        >
                          Yes
                        </Button>
                        &nbsp;&nbsp;
                        <Button className="button is-primary is-light">No</Button>
                      </ul>
                    </div>
                  );
                })}
              </Card>
              <Typography style={{ marginTop: "10px" }}>Members</Typography>
              {isMember.map((item, index) => {
                return (
                  <div style={{ marginLeft: "10px", marginTop: "20px" }} key={index}>
                    <div
                      className="button is-light"
                      id={item.member}
                      style={{ marginTop: "-7px" }}
                      onClick={isUserProfile}
                    >
                      <AccountCircleIcon
                        id={item.member}
                        variant="contained"
                        style={{ fontSize: "2.5rem" }}
                      />
                      {item.member}
                    </div>
                    member of {item.name} &nbsp; &nbsp;
                    <Button
                      id={item.id}
                      value={item.member}
                      variant="contained"
                      className="buttonStyle"
                      onClick={removeMemberProject}
                      style={{ float: "right" }}
                    >
                      <DeleteIcon />
                    </Button>
                  </div>
                );
              })}
            </CardContent>
            <CardActions></CardActions>
          </Card>
        </Grid>
      </Grid>
      <Footer />
    </>
  )
}

export default MemberRequest;
