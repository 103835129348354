import Switch from '@mui/material/Switch';
import Slide from '@mui/material/Slide';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import SharedProjects from './SharedProjects';
import RoomIcon from '@mui/icons-material/Room';

import React, { useMemo, useState } from 'react';

const SimpleSlide = React.memo(({ realUser }) => {
  const [checked, setChecked] = useState(false);
  const handleChange = () => {
    setChecked((prev) => !prev);
  };
  const icon = useMemo(() => (
    <Paper style={{width:"800px",height:"300px",marginBottom:"3rem", position:"absolute"}} elevation={12} >
      <SharedProjects realUser={realUser} setChecked={setChecked} />
    </Paper>
  ), [realUser]);
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:"-2rem" }}>
      <div>
        <FormControlLabel
          name="mapButton"
          control={
            <Switch
              style={{ color: '#444444' }}
              checked={checked}
              onChange={handleChange}
              icon={<RoomIcon />}
              checkedIcon={<RoomIcon />}
            />
          }
        />
        <Slide direction="down" in={checked} mountOnEnter unmountOnExit>
          {icon}
        </Slide>
      </div>
    </div>
  );
});

export default SimpleSlide;
