import React, { useState, useEffect} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import { Grid } from '@mui/material';
import { createJob } from '../services/OnlineService';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';

const AddBlogForm = ({ onClose, realUser, imgLinkZero, imgLinkOne,}) => {

  const [tags, setTags] = useState('');
  const [inputValue, setInputValue] = useState('');

  const [formData, setFormData] = useState({
    user: realUser,
    jobTitle:'',
    hours:'',
    location: '',
    description:'',
    skills:'',
    salary:'',
    contact:'',
    imgLink0:imgLinkZero,
    imgLink1:imgLinkOne,
    tags:'',
    link:'',
    imgLink:'', // Added imgLink to the initial state
  });

  const handleTagsChange = (e) => {
    const inputValue = e.target.value;
    if (tags.length < 3 && inputValue.trim() !== '') {
      setTags([...tags, inputValue.trim()]);
      setInputValue(''); // Clear the input field
    }
    const tagsArray = inputValue.split(' ').slice(0, 3);
    const limitedTags = tagsArray.join(' ');
    setTags(limitedTags);
    handleChange(e)
  };

  const handleChange = (event) => {

    if(imgLinkZero){
      formData['imgLink0'] = imgLinkZero
    }

    if(imgLinkOne){
      formData['imgLink1'] = imgLinkOne
    }
    
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(()=>{
    
    if(imgLinkZero){
      formData['imgLink0'] = imgLinkZero
    }
    if(imgLinkOne){
      formData['imgLink1'] = imgLinkOne
    }
  
    setFormData({
      ...formData,
    });
  },[formData, imgLinkOne, imgLinkZero])

  const handleSubmit = async (event) => {

    formData['imgLink0'] = imgLinkZero
    formData['imgLink1'] = imgLinkOne

    // Call the function to create a job
    await createJob(formData);
    // Set the job data in the parent component
    // Close the form
    onClose();
  };

  return (
        <div >
          <Grid container spacing={1}>
        <Grid item xs={12} mt={1} ml={2} md={4} >
            <Card>
            <CardMedia
                component="img"
                alt="Job banner here"
                style={{height:'100px'}}
                image={formData.imgLink1} //  imgLink is the image URL
              />
              <Avatar
                alt="Remy Sharp"
                src={formData.imgLink0}
                sx={{ width: 140, height:140 ,top:"-4.5rem",marginLeft:"2rem", border: "0.5rem solid white" }}
              />
               <Typography mt={-9} sx={{marginLeft:"3.8rem"}}  variant="body1" color="textSecondary">
               {formData.user}
              </Typography>
              <CardContent item mt={5} ml={2}>
                <Typography gutterBottom variant="h5" component="div">
                {formData.jobTitle}
                </Typography>
                <Typography  mt={1} variant="body2" color="textSecondary">
                {formData.location}
                </Typography>
                <Typography gutterBottom variant="body2" color="textSecondary" component="div">
                {formData.hours}
                </Typography>
                <Typography mt={1} variant="h6" color="textSecondary">
                Information
                </Typography>
                <Typography mt={1} variant="body2" color="textSecondary">
                {formData.description}
                </Typography>
                <Typography mt={1} variant="h6" color="textSecondary">
                Skills
                </Typography>
                <Typography mt={1} variant="body2" color="textSecondary">
                  {formData.skills}
                </Typography>
                <Typography mt={1} variant="h6" color="textSecondary">
                Salary
                </Typography>
                <Typography mt={1} variant="body2" color="textSecondary">
                  {formData.salary}
                </Typography>
                <Typography mt={1} variant="h6" color="textSecondary">
                Contact
                </Typography>
                <Typography mt={1} variant="body2" color="textSecondary">
                  {formData.contact}
                </Typography>
              </CardContent>
              <Button style={{float:"left",marginTop:"0.5rem",marginRight:"0.5rem"}}
                  variant="text"
                  size="small"
                  color="primary"
                  href="https://www.localproject.app/Blogs"
                  rel={`noopener noreferrer tag ${formData.tags}`}
                >
                Tags {formData.tags}
                </Button>
              <CardActions style={{float:"right"}}>
              <Button style={{ float: "right" }} size="small" color="primary" href={`https://www.localproject.app/UserProjects/${formData.user}`}>
                  Creator
                </Button>
                <Button size="small" color="primary" href={formData.link}>
                 Apply
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item mt={5} xs={12} md={4} >
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          />
          <Button onClick={onClose}>Close Form</Button>
        
        <form onSubmit={handleSubmit}>
          <TextField
            label="Job Title"
            variant="outlined"
            inputProps={{ maxLength: 80 }}
            margin="dense"
            required
            name="jobTitle"
            value={formData.jobTitle}
            onChange={handleChange}
          />
             <TextField
            label="Hours"
            sx={{
              marginLeft: '10px', // Adjust the value as needed
            }}
            variant="outlined"
            margin="dense"
            inputProps={{ maxLength: 20 }}
            required
            name="hours"
            value={formData.hours}
            onChange={handleChange}
          />
            <TextField
            label="Location"
            variant="outlined"
            inputProps={{ maxLength: 50 }}
            margin="dense"
            required
            name="location"
            value={formData.location}
            onChange={handleChange}
          />
           <TextField
            label="Description"
            variant="outlined"
            margin="dense"
            required
            inputProps={{ maxLength: 550 }}
            fullWidth
            multiline
            rows={3}
            name="description"
            value={formData.description}
            onChange={handleChange}
          />
            <TextField
            label="Skills"
            variant="outlined"
            margin="dense"
            required
            fullWidth
            inputProps={{ maxLength: 350 }}
            multiline
            rows={3}
            name="skills"
            value={formData.skills}
            onChange={handleChange}
          />
             <TextField     
            label="Salary"
            variant="outlined"
            margin="dense"
            inputProps={{ maxLength: 50 }}
            required
            name="salary"
            value={formData.salary}
            onChange={handleChange}
          />
          <TextField
              sx={{
                marginLeft: '10px', // Adjust the value as needed
              }}
              label="3 Tags"
              variant="outlined"
              margin="dense"
              required
              name="tags"
              value={tags}
              inputProps={{ maxLength: 30 }}
              onChange={handleTagsChange}
            />
             <TextField
            label="Contact"
            variant="outlined"
            margin="dense"
            inputProps={{ maxLength: 30 }}
            required
            name="contact"
            value={formData.contact}
            onChange={handleChange}
          />
            <TextField
             sx={{
              marginLeft: '10px', // Adjust the value as needed
            }}
            label="Apply https://www.exp.app"
            variant="outlined"
            inputProps={{ maxLength: 50 }}
            margin="dense"
            required
            name="link"
            value={formData.link}
            onChange={handleChange}
          />
         
       <Button style={{float:"right",top:"1rem"}} type="submit" variant="contained" color="primary">
            Submit Job
          </Button>
        </form>
      </Grid>
          </Grid>
          </div>
  );
};
export default AddBlogForm;
