import React, {  } from 'react'
import HomePage from '../homePage/HomePage'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { CardActionArea } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const OurMission = () => {
    const nav = useNavigate();
   
    const Home = () => {
        nav('/')
    }
   
    return (
        <>
         <HomePage></HomePage>
            <div className="subContainer" style={{ marginBlock: "1px" }}>
            <Card sx={{ maxWidth: "350px", justifyContent: "center", marginLeft: "auto", marginRight: "auto",marginBottom:"350px", marginTop:"3rem"}}>
                    <CardActionArea onClick={Home}>
                        <div class="subTitle"></div>               
                        <CardMedia
                            component="img"
                            height="140"
                            image="https://i.swncdn.com/media/1200w/cms/CW/71029-man-helping-woman-hike-getty-kieferpix-1200.1200w.tn.jpg"
                            alt="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Gain Rewards
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                Rewards come in different shapes and sizes, from the feeling you get
                                from helping others or to the discounts to help build a project.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </div>
        </>
    )
}
export default OurMission