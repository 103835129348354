import React, { } from 'react';
import HomePage from '../homePage/HomePage';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const OurMission = () => {
    const nav = useNavigate();
    const Home = () => {
        nav('/');
    }
    return (
        <>
            <HomePage></HomePage>
            <div className="subContainer" style={{ marginBlock: "50px" }}>
                <Card sx={{ maxWidth: 620, minWidth: 300, width: "5rem" }}>
                    <CardActionArea onClick={Home}>
                        <div className="subTitle"></div>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Why Volunteer
                            </Typography>
                            <div style={{ height: '550px', overflowY: 'scroll' }}>
                                <Typography variant="body1" color="text.secondary">
                                Volunteering is the act of giving time and energy to help a person, group, or organization without receiving financial compensation.
                                <br /><br />
                                It can involve a wide range of activities, such as working in a soup kitchen, tutoring students, building homes, or cleaning up a park. Many people volunteer to help others to gain new skills or to make a positive impact on their community.
                                <br /><br />
                                Some organizations rely heavily on volunteers to carry out their mission while others use them to supplement the work of paid staff. Overall, volunteering is an opportunity for people to make a meaningful difference in the lives of others and their community.
                                </Typography>
                            </div>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </div>
            {/* Add meta tags here */}
            <meta name="description" content="Find out why volunteering is important and how it can make a positive impact on your community. Discover the benefits of giving your time and energy to help others." />
            <meta name="keywords" content="volunteering, volunteer work, community service, giving back, positive impact, helping others" />
            <meta name="author" content="Phil" />
        </>
    );
}
export default OurMission;