import React, { useState, useEffect } from 'react';

function Noti({ date, message }) {
  const [notificationPermission, setNotificationPermission] = useState('default');

  useEffect(() => {
    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        setNotificationPermission(permission);
        if (permission === 'granted') {
          if (!isReminderSet(date)) {
            showNotification();
            setReminder(date);
          }
        }
      });
    }
  }, [date]);

  const isReminderSet = (date) => {
    const reminders = JSON.parse(localStorage.getItem('reminders')) || [];
    return reminders.includes(date);
  };

  const setReminder = (date) => {
    const reminders = JSON.parse(localStorage.getItem('reminders')) || [];
    reminders.push(date);
    localStorage.setItem('reminders', JSON.stringify(reminders));
  };

  const showNotification = () => {
    if (notificationPermission === 'granted') {
      const notification = new Notification(message);

      // Close the notification after a short delay (e.g., 3 seconds)
      setTimeout(() => {
        notification.close();
      }, 3000);
    }
  };

  return null;
}

export default Noti;
