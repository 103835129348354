import React, { } from 'react';
import HomePage from '../homePage/HomePage';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MissionImg from '../images/OurMission.webp'

const OurMission = () => {
    const Nav = useNavigate()
    const Home = () => {
        Nav('/')
    }
    return (
        <>
            <HomePage></HomePage>
            <div class="subContainer">
            <Card sx={{ maxWidth: "350px", justifyContent: "center", marginLeft: "auto", marginRight: "auto",marginBottom:"350px", marginTop:"3rem"}}>
                    <CardActionArea onClick={Home}>
                        <div class="subTitle"></div>
                        <div className=""  ><span></span></div>
                        <CardMedia
                            component="img"
                            height="140"
                            image={MissionImg}
                            alt="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Our Mission
                            </Typography>
                            <Typography variant="body1" color="text.secondary">
                                To let people share knowledge and connect with others to help find full time work
                                or just to gain experince or simply to help others in need!
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </div>
        </>
    )
}
export default OurMission;
