import React from 'react';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField'; 
import { useForm } from 'react-hook-form';
import { updateInbox } from '../services/InboxService';
import { useNavigate } from 'react-router-dom';
const Swal = require('sweetalert2');
const CreateMessage = ({ user, realUser, realUserImg }) => {
  const nav = useNavigate();
  const { register, handleSubmit } = useForm();
  const name = user?.toString();
  const onSubmit = (data) => {
    if (realUser === '' || realUser === undefined) {
      nav('/Login');
    } else {
      const theTime = new Date().toLocaleTimeString();
      const newData = {
        user: data.user,
        read: false,
        message: `${realUserImg} ${data.createdBy} : ${data.message} @${theTime}`,
        createdBy: realUser,
        id: data.messageId,
        messageId: data.messageId,
        createdByImg: realUserImg,
      };
      data.createdByImg = realUserImg;
      updateInbox(newData).then((response) => {
        Swal.fire('Message Sent to ' + name, 'Congrats!', 'success');
      });
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          {...register('createdByImg')}
          type="text"
          name='createdByImg'
          id='createdByImg'
          value={realUserImg}
          hidden
        />
        <div {...register('user')} type='text' name='user' id='user' hidden value={name} />
        <div
          {...register('createdBy')}
          type='text'
          name='createdBy'
          id='createdBy'
          value={realUser}
          hidden
        />
        <div
          {...register('messageId')}
          type='text'
          name='messageId'
          id='messageId'
          value={realUser + Date.now()}
          hidden
        />
        <div
          {...register('createDate')}
          type='text'
          name='createDate'
          id='createDate'
          value={Date.now()}
          hidden
        />
          <Button
          style={{ marginTop: '10px', float: 'right' }}
          variant='outlined'
          type='submit'
          endIcon={<SendIcon />}
        >
            {name} 
        </Button>
        <TextField
          {...register('message')}
          fullWidth
          variant="standard"
          name='message'
          required
          placeholder='Message'
        />
      </form>
    </>
  );
};
export default CreateMessage;
