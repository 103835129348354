import { getKeys } from '../services/UserService';
import React, { useState } from "react"
import { useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton'
import SendIcon from '@mui/icons-material/Send'
import emailjs from 'emailjs-com';
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid';
import HomePageBar from './HomePageBar';

const Swal = require('sweetalert2');
export default function PasswordRecoveryForm() {
    const nav = useNavigate();
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit } = useForm();

    const onSubmit = async (data, e) => {
        setLoading(true);
        let theKey = [];
        const newData = { email: data.reply_to }
        await getKeys(newData, theKey);
        let newKeyValue;
        if (theKey && theKey.length > 0 && theKey[0].theKey && theKey[0].theKey.length > 0 && theKey[0].theKey[0].key) {
            newKeyValue = theKey[0].theKey[0].key;
        } else {
            Swal.fire(
                'Request Sent',
                'Check your Email',
                'success'
            )
            setLoading(false);
            nav('/')
        }

        const newStr = newKeyValue.replace(/\//g, '');
        data = { ...data, message: `http://www.localproject.app/ResetPassword/${newStr}/${newData.email}` };
        const serviceID = "service_dwqv1s8";
        const templateID = "template_vefjrue";
        const publicKey = "Bl0ezucwaL6MgmwxH";

        await emailjs.send(serviceID, templateID, data, publicKey)
            .then(function (response) {
                console.log('SUCCESS!', response.status, response.text);
                Swal.fire(
                    'Request Sent',
                    'Check your Email',
                    'success'
                )
            }, function (error) {
                console.log('FAILED...', error);
            });
        setLoading(false);
        nav('/')
        e.target.reset();
    };

    return (
        <><HomePageBar></HomePageBar>
            <Grid
                style={{ justifyContent: 'center' }}
                container
                spacing={55}
            >
                <Grid
                    marginTop={4}
                    marginLeft={1}
                    marginRight={1}
                    item
                    xs={12}
                    md={4}
                >
            <Card sx={{ maxWidth: "350px", justifyContent: "center", marginLeft: "auto", marginRight: "auto",marginBottom:"350px", marginTop:"3rem"}}>
                    <CardContent>
                        <div className='createQuestionPic' style={{ height: "200px" }}></div>
                        <form id="formId" onSubmit={handleSubmit(onSubmit)}>
                            <TextField fullWidth  className="form-control" label="Enter Name" variant="outlined" {...register("to_name")} type="text" name="to_name" id="to_name" hidden placeholder="Enter Name..." />
                            <TextField fullWidth style={{  marginTop: '1rem' }} className="form-control" label="Username" variant="outlined" {...register("from_name")} name="from_name" id="from_name" type="text" placeholder="Username" />
                            <TextField fullWidth style={{  marginTop: '1rem' }} className="form-control" label="Email" variant="outlined" {...register("reply_to")} required name="reply_to" id="reply_to" type="email" placeholder="Email" />
                            <div hidden minRows={2} style={{ marginTop: '1rem' }} label="Message" variant="outlined" {...register("message")} name="message" id="message" placeholder="Message" />
                            <LoadingButton style={{ backgroundColor: "#e4f3e0",color: "#444444",marginTop: '1rem'}}
                                fullWidth
                                className="buttonStyle"
                                variant="contained"
                                type="submit"
                                checked={loading}
                                onChange={() => setLoading(!loading)}
                                name="loading"
                                endIcon={<SendIcon />}
                                loading={loading}
                                loadingPosition="end"
                            >Recover</LoadingButton>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
            </Grid>
        </>
    )
}