import React, { useState, useRef } from "react";
import { deleteImages } from "../services/InboxService";
//import { useParams } from 'react-router-dom';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Checkbox from '@mui/material/Checkbox';
import VideoPlayer from "./VideoPlayer";
import { Button } from '@mui/material'
const Swal = require('sweetalert2');
function getLastThreeLetters(str) {
  if (str.length >= 3) {
    return str.slice(-3);
  } else {
    return str;
  }
}
const Images = ({user, realUser,imagess, userImages,setImgDelete}) => {
  const [checked] = React.useState([false, true]);
  const [deleteImg, setDeleteImg] = useState([]);
  const [imgId, setImgId] = useState([]);
  let newUserImages = []
  let width = 2;
  let height = 2;
    for (const key in userImages) {
      if (Object.hasOwnProperty.call(userImages, key)) {
        const newImages = userImages[key].images;
        for (const key in newImages) {
          if (Object.hasOwnProperty.call(newImages, key)) {
            const element = newImages[key]['imgLink'];
            const title = newImages[key]['title'];
            const description = newImages[key]['description'];
            const id = newImages[key]['_id'];
            let photos = { id: id, src: element, width: width, height: height, title: title, description: description }
            newUserImages.push(photos);
          }
        }
      }
    }
  let newImages = []
  imagess.forEach( element => {
    let addString = "https://localprojectapi-api.vercel.app/file/" + element;
    let photos = { src: addString, width: width, height: height }
     newImages.push(photos);
  });
  const reportImg = () => {
    Swal.fire({  
      title: 'Report Image',
      showDenyButton: true ,
      confirmButtonText: 'Yes Report',
      denyButtonText: 'Not Today!',
    }).then((result) => {
      if (result.isConfirmed) {
        imgId.forEach(_id => {
          const data = { user, _id };
          deleteImages(data);
          setImgDelete(true);
        });
        } else { 
      }  if (result.isDenied) {
      }
    })
  }
  const myRef = useRef(null);
  
  const handleChange = (e) => {
    
    let markImg = 1;
    const image = e.target.value;
    const id = e.target.name;
    for (const key in deleteImg) {
      if (Object.hasOwnProperty.call(deleteImg, key)) {
        const element = deleteImg[key];
        if (element === image) {
          markImg = 2;
        }
      }
    }
    if (markImg === 2) {
      setDeleteImg(prev => prev.filter(deleteImg => deleteImg !== image))
      setImgId(prev => prev.filter(imgId => imgId !== id))
      setImgDelete()
    } else {
      setDeleteImg(deleteImg => [...deleteImg, image]);
      setImgId(imgId => [...imgId, id]);
      setImgDelete()
    }
  };

  async function deleteAllImages() {
  Swal.fire({
    title: 'Delete Images',
    showDenyButton: true ,
    showCancelButton: true,
    confirmButtonText: 'Delete',
    denyButtonText: 'Another time',
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      imgId.forEach(_id => {
        const data = { user, _id };
        deleteImages(data);
        setImgDelete(true);
      });
      } else { 
    }  if (result.isDenied) {
    }
  })
}
return (
  <>
    <div>&nbsp;&nbsp;{user === realUser && <Button variant="outlined" onClick={deleteAllImages} >Delete</Button>}&nbsp;&nbsp;
    {user === realUser &&  <Button variant="outlined" style={{float:"left",marginRight:"1rem"}}  >Report</Button>}{deleteImg.map((item) => {
      return (
        <div  style={{ float: "left",marginTop:"10px" }}>
          <img key={item.id} style={{height:"50px",width:"50px"}} alt={item.description} src={item} />
        </div>);
    })}
    </div>
    <ImageList style={{marginTop:"20px",height:"800px"}} cols={4}>
      {newUserImages.reverse().map((item) => (
      <ImageListItem key={item.id}>
      {getLastThreeLetters(item.src) === 'mp4' ? (
        <VideoPlayer filename={item.src} />
      ) : (
        <img
          loading="lazy"
          src={`${item.src}?w=248&fit=crop&auto=format`}
          srcSet={`${item.src}?w=248&fit=crop&auto=format&dpr=2 2x`}
          alt={item.title}
          name={item.id}
          id={item.id}
          ref={myRef}
        />
      )}
      <Checkbox
        loading="lazy"
        control={<Checkbox checked={checked[0]} />}
        value={item.src}
        name={item.id}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <ImageListItemBar
        loading="lazy"
        sx={{ marginBottom: '40px' }}
        title={item.title}
        subtitle={<span>{item.description}</span>}
        position="bottom"
      />
    </ImageListItem>
      ))}
    </ImageList>
  </>
)
}
export default Images;
