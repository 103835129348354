export async function shareProject() {
    const response =  await fetch('https://localprojectapi-api.vercel.app/api/shareProject');
    return await response.json();
  }

export async function createShared(shared) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/createShared`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ shared: shared })
    })
    return await response.json();
}

export async function deleteSharedProject(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/deleteSharedProject`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ shared: data })
    })
    return await response.json();
}

export async function uploadSharedProjects(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/uploadSharedProjects`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ shared: data })
    })
    return await response.json();
}

export async function deleteProject(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/deleteProject`, {
        method: 'PUT', 
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ shared: data })
    })
    return await response.json();
}