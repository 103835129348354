import React, { useState, useEffect } from 'react';
//import { ValidUser } from './ValidateUsers';
import LoginBarTop from './LoginBarTop';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { addExceptedFriend, blockUser, getFriends } from '../services/InboxService';
import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Button, Typography } from '@mui/material';

const AcceptFriendsRequest = () => {
    const nav = useNavigate()
    const location = useLocation()
    const state = location.state;
    const user = state.user;
    const realUser = state.realUser;

    if (realUser === null) {
        nav('/');
    }

    const [friendReq, setFriendReq] = useState([]);
    const [friendAccepted, setFriendAccepted] = useState([]);
    const [blocked, setBlocked] = useState([]);
    const [removeFriend, setRemoveFriend] = useState([""]);
    const [gotFriends, setGotFriends] = useState(false);

    useEffect(() => {
        if (!gotFriends) {
            getFriendslist();
            async function getFriendslist() {
                const balls = { user }
                await getFriends(balls).then(response => {
                    const newFriends = response;
                    for (let index = 0; index < newFriends.length; index++) {
                        const newPal = newFriends[index].friends;
                        for (const key in newPal) {
                            if (Object.hasOwnProperty.call(newPal, key)) {
                                const dick = newPal[key].friends;
                                const isFriend = newPal[key].isFriend;
                                const friendId = newPal[key]._id;
                                const whoBlocked = newPal[key].whoBlocked;
                                const visitorImg = newPal[key].visitorImg;
                                const newFriend = { dick, friendId, visitorImg };
                                if (isFriend === 0) {
                                    setFriendReq(friendReq => [...friendReq, newFriend]);
                                }
                                if (isFriend === 1) {
                                    setFriendAccepted(friendAccepted => [...friendAccepted, newFriend]);
                                }
                                if (isFriend === 4 && whoBlocked === user) {
                                    setBlocked(blocked => [...blocked, newFriend]);
                                }
                                setGotFriends(true);
                            }
                        }
                    }
                });
            }
        }

    }, [gotFriends, user]);

    async function isExceptedFriend(e) {
        const friend = e.target.value;
        const data = { user, friend };
        addExceptedFriend(data);
        const name = e.target.name;
        const newData = { user: name, friend };
        await addExceptedFriend(newData);

    }

    async function isRemoveFriend(e) {
        const friend = e.target.value;
        const data = { user, friend };
        removeFriend(data);
        const newData = { user: user, friend: friend };
        await removeFriend(newData);
        setRemoveFriend(newData);
    }

    async function isBlockedUser(e) {
        const friend = e.target.value;
        const data = { user, friend, isFriend: 4 };
        blockUser(data);
        const newData = { user: user, friend: friend, isFriend: 4 };
        await blockUser(newData);

    }

    async function isUnblocked(e) {
        const friend = e.target.value;
        const data = { user, friend, isFriend: 3 };
        blockUser(data);
        const newData = { user: user, friend: friend, isFriend: 3 };
        await blockUser(newData);
    }
    
    return (
        <><>
            <LoginBarTop realUser={realUser}></LoginBarTop>
            <Grid
                id="photoBlog"
                style={{ justifyContent: 'center' }}
                container
                spacing={1}
                mt={4}
            >
                <Grid
                    item xs={4} sx={{ minWidth: "400px" }}
                >
                    <Card>
                        <CardContent sx={{ flexGrow: 1 }}>
                            <Typography style={{ top: "20px" }}>Requests</Typography>
                            <Card style={{ marginTop: "45px" }}>{friendReq.map((item, index) => {
                                return (
                                    <><div style={{ marginBlock: "10px", float: "right" }} key={index}>
                                        <ul value={item.friendId} name={item.dick} key={index}>
                                            <Avatar src={item.imgLink} />{item.dick} wants to be friends &nbsp;&nbsp;
                                            <button value={item.friendId} name={item.dick} onClick={isExceptedFriend} className="button is-primary is-light">Yes</button>
                                             &nbsp;&nbsp;
                                            <button value={item.friendId} onClick={isRemoveFriend} className="button is-primary is-light">No</button>&nbsp;&nbsp;
                                            <button value={item.friendId} onClick={isBlockedUser} className="button is-primary is-light">Block</button></ul>
                                    </div>
                                        <Avatar
                                            alt="Remy Sharp"
                                            src={item.visitorImg}
                                            sx={{ width: 50, height: 50, border: "0.1rem solid white" }}
                                        />
                                    </>);
                            })}
                            </Card>
                            <Typography  style={{ marginTop: "60px" }}>Accepted</Typography><Card style={{ marginTop: "30px" }}>{friendAccepted.map((item, index) => {
                                return (
                                    <>
                                        <Avatar
                                            alt="Remy Sharp"
                                            rc={item.visitorImg}
                                            sx={{ width: 50, height: 50, border: "0.1rem solid white" }}
                                        />
                                        <><div style={{ marginLeft: "10px", marginTop: "10px" }} key={index}>
                                            <ul value={item.friendId} key={index}>{item.dick} is your friend &nbsp; &nbsp; <Button value={item.friendId} onClick={isRemoveFriend} variant="contained" className="buttonStyle">Remove</Button>&nbsp; &nbsp;<Button value={item.friendId} onClick={isBlockedUser} variant="contained" className="buttonStyle">Block</Button></ul>
                                        </div></></>);
                            })}
                            </Card>
                            <Typography style={{ marginTop: "60px" }}>Blocked</Typography>
                            <Card style={{ marginTop: "30px" }}>{blocked.map((item, index) => {
                                return (
                                    <div style={{ marginLeft: "10px", marginTop: "10px" }} key={index}>
                                        <ul value={item.friendId} key={index}>{item.dick} is blocked &nbsp; &nbsp; <Button value={item.friendId} onClick={isRemoveFriend} variant="contained" className="buttonStyle">Hide</Button>&nbsp; &nbsp;<Button value={item.friendId} onClick={isUnblocked} variant="contained" className="buttonStyle">Unblock</Button></ul>
                                    </div>);
                            })}
                            </Card>
                        </CardContent>
                        <CardActions></CardActions>
                    </Card>
                </Grid>
            </Grid>
        </></>
    )
}
export default AcceptFriendsRequest;