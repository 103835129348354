import { Card } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';


const VideoPlayer = ({ filename, onDurationChange  }) => {

  const [videoDuration, setVideoDuration] = useState(null);
  

  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('loadedmetadata', () => {
        setVideoDuration(videoElement.duration);
        onDurationChange(videoElement.duration);
      });
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('loadedmetadata', () => {
          setVideoDuration(null);
        });
      }
    };
  }, [onDurationChange]);

  const handlePlay = () => {
    videoRef.current.play();
  };

  const handlePause = () => {
    videoRef.current.pause();
  };

  const handleLoadedMetadata = () => {
    setVideoDuration(videoRef.current.duration);
    onDurationChange(videoRef.current.duration);
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
        
      fetch(`${filename}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(videoBlob => {
          const videoUrl = URL.createObjectURL(videoBlob);
          videoElement.src = videoUrl;
        })
        .catch(error => console.error('Error fetching video:', error));
    }
  }, [filename]);

  return (
    <Card className="video-container">
      <video ref={videoRef} controls onPlay={handlePlay}
     onPause={handlePause} onLoadedMetadata={handleLoadedMetadata}>
      <source src={filename} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    {videoDuration}
  </Card>
  );
};
export default VideoPlayer;