import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: '20px',
    marginTop:"2rem",
    height:"50px",
    alignItems: 'center',
    justifyContent: 'center', // Added this line to center horizontally
    '& > *': {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  name: {
    fontWeight: 'bold',
  },
  title: {
    fontStyle: 'italic',
  },
}));
function TestimonialAvatar({ image, name, title }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Avatar alt={name} src={image} className={classes.avatar} />
      <div>
        <Typography variant="subtitle1" className={classes.name}>
          {name}
        </Typography>
        <Typography variant="body2" className={classes.title}>
          {title}
        </Typography>
      </div>
    </div>
  );
}
TestimonialAvatar.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
export default TestimonialAvatar;