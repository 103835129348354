import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  chatContainer: {
    height: '250px',
    overflowY: 'clip',
    marginBottom: '5px',
    backgroundColor: 'transparent',
  },
  message: {
    padding: '5px',
    marginBottom: '5px',
    borderRadius: '5px',
    fontFamily: "'DreamyFont', cursive",
    fontSize: '18px',
    color: '#777',
  },
  '@keyframes float': {
    '0%': {
      transform: 'translateY(0)',
    },
    '50%': {
      transform: 'translateY(-10px)',
    },
    '100%': {
      transform: 'translateY(0)',
    },
  },
});

const ChatRoom = ({ messages }) => {
  const classes = useStyles();
  const chatContainerRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom of the chat container whenever new messages are added
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div  className={classes.chatContainer} ref={chatContainerRef}>
     {messages.slice().reverse().map((message, index) => (
        <div style={{backgroundColor:"transparent"}} key={index} className={classes.message}>
         - {message}
        </div>
      ))}
    </div>
  );
};

export default ChatRoom;
