import React, { useEffect, useState } from 'react'
//import { ValidUser } from './ValidateUsers'
import NativeSelect from '@mui/material/NativeSelect'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@mui/material/InputLabel'
import { MapContainer, TileLayer, useMapEvents, Marker, Popup, LayerGroup, LayersControl, useMap } from 'react-leaflet'
import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch'
import { useForm } from "react-hook-form"
import { addProjectMembers, createProject } from '../services/InboxService'
import projectIcon from "./projectIcon"
import { getLocation } from '../services/UserService'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Textarea from '@mui/joy/Textarea'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { useLocation, useNavigate } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send'
import LoadingButton from '@mui/lab/LoadingButton'
import LoginBarTop from './LoginBarTop'
import SearchIcon from '@mui/icons-material/Search'
import { Button } from '@mui/material'
import Box from '@material-ui/core/Box'
import Grid from '@mui/material/Grid'
import Footer from './Footer'

const setHelpTitle = [
  { title: 'Volunteer' },
];

const Swal = require('sweetalert2');

const CreateVolunteer = () => {
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState('');
  const [type, setType] = useState("checkboxes-tags-demo-option-0");
  const [position, setPosition] = useState(null);
  const [location, setLocations] = useState("");
  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");
  const [date, setDate] = useState([null, null]);
  const [dates, setDates] = useState("");
  const { register, handleSubmit } = useForm();
  const nav = useNavigate();
  const newLocation = useLocation();
  const state = newLocation.state;
  const user = state.realUser;
  const realUser = state.realUser;

  if (user === null) {
    nav('/');
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSearch = () => {
    // your search function here
    console.log(`Searching for ${inputValue}`);

    const t = inputValue
    try {
      getLocation(t)
        .then((search) => {
          const latValue = search[0]?.lat;
          const lonValue = search[0]?.lon;
          if (latValue && lonValue) {
            setLat(latValue);
            setLon(lonValue);
            setLocations(search[0]?.display_name);
            let latlng = { lat: latValue, lng: lonValue };
            setPosition(latlng);
            document.getElementById("location").focus();
            document.getElementById("lon").focus();
            document.getElementById("lat").focus();
          } else {
            throw new Error("Unable to retrieve latitude and longitude values");
          }
        })
        .catch((error) => {
          console.error("Error occurred while retrieving location:", error);
          alert("Location not updated");
        });
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      alert("Location not updated");
    }
  }

  const getAutoCompleteValue = (e) => {
    if (e === 'checkboxes-tags-demo-option-0') {
      setType(e);
    }

    const james = document.getElementById('requested').value;
    if (james === "Job") {
      const james = document.getElementById('Job').value;
      james.style.display = "block";
    }
    setType(e);
    console.log(type)
  };


  function LocationMarker() {
    const [position, setPosition] = useState(null)
    const map = useMapEvents({
      click() {
        map.locate()
      },
      locationfound(e) {
        setPosition(e.latlng)
        map.flyTo(e.latlng, map.getZoom())
      },
    })

    return position === null ? null : (
      <Marker position={position}>
        <Popup>You are here</Popup>
      </Marker>
    )
  }

  const Search = (search) => {
    const map = useMap() // access to leaflet map
    const { provider } = search

    useEffect(() => {
      window.onbeforeunload = function (e) {
      };

      const searchControl = new GeoSearchControl({
        provider,
        marker: {
          icon: projectIcon,
        }
      })
      map.on('geosearch/showlocation', searchEventHandler);
      map.addControl(searchControl) // this is how you add a control in vanilla leaflet
      return () => map.removeControl(searchControl)
    }, [map, search, provider])
    return null // don't want anything to show up from this comp
  }

  function searchEventHandler(result) {
    const location = result.location.label;
    const lat = result.location.y;
    const lon = result.location.x;
    setLocations(location);
    setLat(lat);
    setLon(lon);
  }

  function LocationMarker() {
    const map = useMapEvents({
      locationfound(e) {
        setPosition(e.latlng);
        map.flyTo(e.latlng, map.getZoom());
      },
    });
    useEffect(() => {
      if (position !== null) {
        map.flyTo(position, map.getZoom());
      }
    }, [position, map]);
    return position === null ? null : (
      <Marker position={position} icon={projectIcon}>
        <Popup>Volunteering Starts Here!</Popup>
      </Marker>
    );
  }

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  let myArr = [{ lat: 51.505, lng: -0.09 }];
  window.dispatchEvent(new Event('resize'));

  const onSubmit = async (data, e) => {
    setLoading(true)
    let ImgId = document.getElementById("mainImg");
    data["dates"] = dates;
    data["lon"] = lon;
    data["location"] = location;
    data["imgLogo"] = ImgId.currentSrc;

    if (data.name === undefined || data.lon === "") {
      Swal.fire('No Volunteer information');
    } else {
      await createProject(data);
      let newData = { project : data.name, user: realUser};
      await addProjectMembers(newData)
      try {
        const result = await Swal.fire({
          title: 'Goto ' + data.name + ' ?',
          showDenyButton: true,
          confirmButtonText: 'Go to ' + data.name + ' Volunteer',
        });
        if (result.isConfirmed) {
          setTimeout(function () {
            nav(`/UserProjects`, { state: { user: realUser, realUser } });
            e.target.reset();
          }, 3);
        } else if (result.isDenied) {
          e.target.reset();
        }
      } catch (error) {
        console.error(error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        });
      }
    }
    setLoading(false)
  };

  return (
    <>
      <LoginBarTop realUser={realUser} ></LoginBarTop>
      <Grid
       id="photoBlog"
       style={{ justifyContent: 'center' }}
       container spacing={2} columns={16} mt={4}
      >
 <Grid item xs={4} sx={{ minWidth: "400px" }}>
              <Typography gutterBottom variant="h5" component="h2"
              >
                Start Volunteering Today!
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Autocomplete
                  style={{ marginTop: "20px" }}
                  id="checkboxes-tags-demo"
                  options={setHelpTitle}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.title}
                  onChange={(e) => getAutoCompleteValue(e.target.id)}
                  renderOption={(props, option, { selected }) => (
                    <li  {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        id="requested" />
                      {option.title}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} style={{ color: "white" }} label="Request Type" placeholder="Skills needed" />
                  )} />
                {type === 'checkboxes-tags-demo-option-0' && <TextField className="form-control" {...register("name")} inputProps={{ maxLength: 30 }} style={{ marginTop: "20px" }} label="Voluteering name" variant="outlined" placeholder="Voluteering name" name="name" id="Project" required />}
                {type === 'checkboxes-tags-demo-option-0' && <Textarea style={{ marginTop: "20px" }} minRows={4} label="Description" variant="outlined" {...register("description")} placeholder={"Define what you’re looking to achieve as a Volunteer?"} className="form-control" name="description" id="description" required />}
                {type === 'checkboxes-tags-demo-option-0' && <Textarea style={{ marginTop: "20px" }} minRows={4} label="Tasks to get the job Done" variant="outlined" {...register("achieve")} placeholder={"What are you good at?"} className="form-control" name="achieve" id="achieve" required />}
                {type === 'checkboxes-tags-demo-option-0' && <Textarea style={{ marginTop: "20px" }} minRows={2} label="Budget" variant="outlined" {...register("budget")} placeholder={"What Qualities can you bring? "} className="form-control" name="budget" id="budget" required />}
                {type === 'checkboxes-tags-demo-option-4' && <TextField className="form-control" {...register("name")} inputProps={{ maxLength: 30 }} style={{ marginTop: "20px" }} label="General help title" variant="outlined" placeholder="General help title" name="general" id="general" required />}
                {type === 'checkboxes-tags-demo-option-5' && <TextField className="form-control" {...register("name")} inputProps={{ maxLength: 30 }} style={{ marginTop: "20px" }} label="Title" variant="outlined" placeholder="Title" name="title" id="title" required />}
                {type === 'checkboxes-tags-demo-option-1' && <TextField className="form-control" {...register("name")} inputProps={{ maxLength: 30 }} style={{ marginTop: "20px" }} label="Volunteer Title" variant="outlined" placeholder="Volunteer Title" name="volunteerTitle" id="volunteerTitle" required />}
                {type === 'checkboxes-tags-demo-option-1' && <TextField className="form-control" {...register("skill")} inputProps={{ maxLength: 30 }} style={{ marginTop: "20px" }} label="Profession needed" variant="outlined" placeholder="Profession needed" name="skill" id="skill" required />}
                {type === 'checkboxes-tags-demo-option-3' && <TextField className="form-control" {...register("jobType")} inputProps={{ maxLength: 30 }} style={{ marginTop: "20px" }} label="What kind of work" variant="outlined" placeholder="What kind of work?" name="jobType" id="jobType" required />}
                {type === 'checkboxes-tags-demo-option-2' && <TextField className="form-control" {...register("jobTitle")} inputProps={{ maxLength: 30 }} style={{ marginTop: "20px" }} label="Job Title" variant="outlined" placeholder="Job title" name="jobTitle" id="jobTitle" required />}
                {type === 'checkboxes-tags-demo-option-2' && <TextField className="form-control" {...register("companyName")} inputProps={{ maxLength: 30 }} style={{ marginTop: "20px" }} label="Logo Name" variant="outlined" placeholder="Company name" name="company" id="company" required />}
                <div style={{ marginTop: "20px" }}>
                  <LocalizationProvider
                    style={{ marginTop: "20px" }}
                    dateAdapter={AdapterDayjs}
                    localeText={{ start: 'From', end: 'To' }}
                  >
                    <TextField className="form-control" {...register("website")} inputProps={{ maxLength: 30 }} style={{ marginBottom: "15px" }} label="Website" variant="outlined" placeholder="https://www.example.com" name="website" id="website" />
                    <DateRangePicker
                      value={date}
                      onChange={(newValue) => {
                        setDate(newValue);
                        const startDate = newValue[0].toISOString().split('T')[0];
                        const endDate = newValue[1].toISOString().split('T')[0];
                        const dateRange = `${startDate} - ${endDate}`;
                        setDates(dateRange);
                      }}

                      renderInput={(startProps, endProps) => (
                        <React.Fragment>
                          <TextField {...startProps} />
                          <Box sx={{ mx: 2 }}>  </Box>
                          <TextField style={{ marginLeft: "5px" }}{...endProps} />
                        </React.Fragment>
                      )}
                    />
                  </LocalizationProvider>
                </div>
                {type === 'checkboxes-tags-demo-option-1' &&
                  <><InputLabel style={{ marginTop: "20px" }} variant="standard" htmlFor="uncontrolled-native">
                    How many volunteers
                  </InputLabel><NativeSelect
                    style={{ marginBottom: "20px" }}
                    defaultValue={1}
                    variant="outlined"
                    inputProps={{
                      name: 'age',
                      id: 'uncontrolled-native',
                    }}
                  >
                      <option value={1}>One</option>
                      <option value={2}>Two</option>
                      <option value={3}>Three</option>
                      <option value={4}>Four or more</option>
                    </NativeSelect></>}
                <MapContainer id="mapPane" style={{ height: "250px", width: "-moz-available", float: "center" }}
                  center={{ lat: myArr[0].lat, lng: myArr[0].lng }}
                  zoom={7}
                  scrollWheelZoom={false}
                >
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  <LayersControl position="topright">
                    <LayersControl.Overlay checked name="satellite">
                      <LayerGroup>
                        <TileLayer
                          url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                          maxZoom={18}
                          subdomains={['mt1', 'mt2', 'mt3']} />
                        <LocationMarker />
                      </LayerGroup>
                    </LayersControl.Overlay>
                    <LayersControl.Overlay name="Feature group">
                    </LayersControl.Overlay>
                  </LayersControl>
                  <Search provider={new OpenStreetMapProvider()} />
                  <LocationMarker />
                </MapContainer>
                <div>
                  <TextField
                    style={{ marginTop: "20px" }}
                    inputProps={{ maxLength: 30 }}
                    label="Location"
                    variant="outlined"
                    width="200px"
                    type="text"
                    placeholder="Search Location"
                    value={inputValue}
                    className="form-control"
                    onChange={handleInputChange}
                    InputProps={{
                      endAdornment: (
                        <Button className='ButtonStyle' style={{ marginTop: "5px" }}>
                          <SearchIcon onClick={handleSearch} />
                        </Button>
                      )
                    }}
                  />
                </div>
                <TextField variant="outlined" {...register("createdBy")} placeholder="Created By" className="form-control" name="createdBy" id="createdBy" value={user} hidden />
                <TextField {...register("user")} placeholder="Created By" className="form-control" name="user" id="user" value={user} hidden />
                {type === 'checkboxes-tags-demo-option-1' && <Textarea style={{ marginTop: "20px" }} minRows={4} label=">What do you need help with?" variant="outlined" {...register("description")} placeholder={"What do you need help with?"} className="form-control" name="description" id="description" required />}
                {type === 'checkboxes-tags-demo-option-3' && <Textarea style={{ marginTop: "20px" }} minRows={4} label="Description" variant="outlined" {...register("description")} placeholder={"What experince can you bring to this job?"} className="form-control" name="description" id="description" required />}
                {type === 'checkboxes-tags-demo-option-4' && <Textarea style={{ marginTop: "20px" }} minRows={4} label="Description" variant="outlined" {...register("description")} placeholder={"What do you need help with?"} className="form-control" name="description" id="description" required />}
                {type === 'checkboxes-tags-demo-option-5' && <Textarea style={{ marginTop: "20px" }} minRows={4} label="Description" variant="outlined" {...register("description")} placeholder={"Write something here.."} className="form-control" name="description" id="description" required />}
                {type === 'checkboxes-tags-demo-option-2' && <Textarea style={{ marginTop: "20px" }} minRows={4} label="Description" variant="outlined" {...register("description")} placeholder={"Job Description"} className="form-control" name="description" id="description" required />}
                {type === 'checkboxes-tags-demo-option-2' && <TextField className="form-control" {...register("monthWage")} inputProps={{ maxLength: 30 }} style={{ marginTop: "20px" }} label="Monthly wage" variant="outlined" placeholder="monthly Wage" name="monthWage" id="monthWage" required />}
                <div className="field" style={{ marginTop: "20px" }}
                  multiple>
                  <label className="label">Location check</label>
                  <input {...register("location")} placeholder={"Seach location on map"} className="input is-focused" name="location" id="location" value={location} readOnly />
                  <div>
                    <div className="field" style={{ marginTop: "20px" }}>
                      <input {...register("lat")} placeholder={"Seach location on map"} className="input is-focused" name="lat" id="lat" value={lat} readOnly />
                    </div>
                    <div className="field">
                      <input {...register("lon")} placeholder={"Seach location on map"} className="input is-focused" name="lon" id="lon" value={lon} readOnly />
                    </div>
                    <div className="buttons">
                      <LoadingButton
                        style={{ color: "white", marginRight: "10px" }}
                        className="buttonStyle"
                        type="submit"
                        checked={loading}
                        onChange={() => setLoading(!loading)}
                        name="loading"
                        color="primary"
                        // onClick={handleClick}
                        endIcon={<SendIcon />}
                        loading={loading}
                        loadingPosition="end"
                        variant="contained"
                      >
                        Start Volunteering!
                      </LoadingButton>
                      <Typography style={{marginTop:"10px"}}>
                Once created you need to share with the map to be found...
              </Typography>
                    </div>
                  </div>
                </div>
              </form>
              </Grid>
      </Grid>
      <div className="container" style={{ maxWidth: "800px", marginTop: "105px", marginBlock: "5px" }}>
      </div><Footer /></>
  )
}
export default CreateVolunteer;
