import React, { } from 'react';
import HomePage from '../homePage/HomePage';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Button from '@mui/material/Button';

const Privacy = () => {
    const nav = useNavigate();
    const gotoHome = () => {
        nav('/')
    }
    return (
        <> 
            <HomePage></HomePage>
            <div class="subContainer" sx={{ maxWidth: "350px", justifyContent: "center", marginLeft: "auto", marginRight: "auto",marginBottom:"350px", marginTop:"3rem"}}>
                <Card sx={{ maxWidth: 620, minWidth: 300, width: "5rem" }}>
                    <CardActionArea>
                        <div class="subTitle"></div>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Privacy Policy
                            </Typography>
                            <div style={{ height: '300px', overflowY: 'scroll' }}>
                                <Typography variant="body2" color="text.secondary">
                                This Privacy Policy describes how localproject.app collects, uses, and discloses personal information. Personal information means any information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular individual or household.

Collection of Information
We may collect personal information from you when you use our website, register for an account, fill out a form, or otherwise interact with us. The types of personal information we may collect include your name, email address, phone number, and any other information you provide to us.

Use of Information
We may use your personal information to provide you with our services, communicate with you about our services, and improve our services. We may also use your personal information to send you promotional emails or other communications, but you can opt-out of receiving such communications at any time.

Disclosure of Information
We may disclose your personal information to our service providers, such as hosting and payment processing providers, to assist us in providing our services to you. We may also disclose your personal information as required by law or to protect our legal rights.

Security
We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security.

Cookies
We may use cookies to collect information about how you use our website and to provide you with personalized content and advertising. You can adjust your browser settings to refuse cookies or to alert you when cookies are being sent.

Third-Party Links
Our website may contain links to third-party websites, which are governed by their own privacy policies. We are not responsible for the privacy practices or content of those websites.

Changes to Privacy Policy
We may update this Privacy Policy from time to time. If we make any material changes, we will notify you by email or by posting a notice on our website prior to the change becoming effective.

Contact Us
If you have any questions or concerns about our Privacy Policy, please contact us via the website.
                                </Typography>
                            </div>
                        </CardContent>
                    </CardActionArea>
                    <Button onClick={gotoHome}>Agree</Button><Button>Disagree</Button>
                </Card>
            </div>
        </>
    )
}
export default Privacy;