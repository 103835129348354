import React from 'react'
import EditTaskModal from './EditTaskModal'
import { Button } from '@mui/material'

 const Tasks = ({tasks,delTask, taskEdited, user, setUpdatedTasks}) => {
    const TaskRow = (tasks,index) => {
        return(
     <tr  key = {index}  >
            {user === tasks.createdBy &&<><td style={{maxWidth:"50px"}}>{tasks.createdBy}</td><td>{tasks.task}</td><td>{tasks.assignee}</td><td>{tasks.status}</td><td> <EditTaskModal tasks={tasks} setUpdatedTasks={setUpdatedTasks} taskEdited={taskEdited} />
                <Button  id="bt3" type="button" onClick={(e) => {
                    if (user === tasks.createdBy)
                        delTask(tasks._id); else { alert("You did not create this task")} 
                } }  >Delete</Button></td></>}
              </tr>
          )
    }

    const taskTable = tasks.map((tasks,index) => TaskRow(tasks,index))
    return(
            <>
            <div className="table">
            <thead>
                <tr>
                    <th style={{minWidth:"80px"}}>Creator</th>
                    <th style={{minWidth:"80px"}}>Task</th>
                    <th style={{minWidth:"80px"}}>Assignee</th>
                    <th style={{minWidth:"80px"}}>Status</th>
                </tr>
            </thead>
            <tbody >
                {taskTable}
            </tbody>
        </div></>
    )
}
export default Tasks;