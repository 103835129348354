import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import HomePageBar from '../components/HomePageBar'
import Button from '@mui/material/Button'
import CardActions from '@mui/material/CardActions'
import CardMedia from '@mui/material/CardMedia'
import Grid from '@mui/material/Grid'
import { productss } from '../data/NewsData';

const Products = () => {
  const [showFullText, setShowFullText] = useState(false);
    return (
       <><HomePageBar></HomePageBar><div style={{ display: 'grid' }}>
            <Typography  style={{ display: 'grid', justifyContent: "center", marginLeft: "50px",marginBottom:"10px" }} gutterBottom variant="h4" component="h2"
            >The Best Local Project Products.</Typography>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>   
            </div> <Grid container spacing={3} justifyContent="center">
                {productss.map((productss, index) => (
                    <Grid item xs={12} md={2} key={index}>
                        <Card>
                            <CardMedia
                                component="img"
                                image={productss.image}
                                alt="Volunteer"
                                sx={{
                                    objectFit: 'cover',
                                }} />
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    {productss.title}
                                </Typography>
                                {/* Step 2: Render text conditionally */}
                                {showFullText || productss.description.length <= 100 ? (
                                    <Typography>{productss.description}</Typography>
                                ) : (
                                    <>
                                        <Typography>{productss.description.slice(0, 144)}</Typography>
                                        <Button
                                            onClick={() => setShowFullText(true)}
                                            style={{
                                                color: 'black',
                                                backgroundColor: 'white',
                                                bottom: '-3px',
                                            }}
                                        >
                                            Read More
                                        </Button>
                                    </>
                                )}
                                {productss.extra !== '' && (
                                    <Button
                                        sx={{ float: "right", marginBottom: "6px" }}
                                        className="buttonStyle"
                                        href={productss.link}
                                        style={{ color: 'black', backgroundColor: 'yellow' }}
                                    >
                                        Buy Here
                                    </Button>
                                )}
                            </CardContent>
                            <CardActions></CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid></>
    )
}
export default Products;