import React, { } from 'react';
import HomePage from '../homePage/HomePage';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import LpApp from '../images/Local-Project-App.webp'

const SneakPeak = () => {
    
    const nav = useNavigate()
    const Home = () => {
        nav('/')
    }
  
    return (
        <>
            <HomePage></HomePage>
            <div class="subContainer" style={{zIndex:"1"}}>
            <Card onClick={Home} sx={{ maxWidth: 620, minWidth: 300}}>              
                    <CardMedia
                        component="img"
                        style={{ objectFit: 'cover', height: '20rem' }}
                        image={LpApp}
                        alt="Volunteer App"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                           Download the Android App here  📱 <a href="https://play.google.com/store/apps/details?id=localproject.app">Click to download</a>
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        Once logged in you can enjoy all the benefits of searching for local skills and creating your own project via location just like with other apps such as AirB&B. IOS at later date.
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        </>
    )
}
export default SneakPeak;