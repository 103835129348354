import projectPic from '../images/localproject-Icon2.webp'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
export const HomePageHeader = ({ hideTestimonialOnSmallScreens }) => {
    const nav = useNavigate()
    return (
        <><div style={{ marginLeft: "2rem", display: 'flex', justifyContent: 'center',marginTop:"2rem" }}>
            <Button variant="text" onClick={() => nav('/')} size="large">
                <img src={projectPic} alt="Local Project Logo" />
            </Button>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {hideTestimonialOnSmallScreens ? (<div className='centered-text'>
                    "Manage or Share Your Project or Business, Connect with Like-Minded People Worldwide"
                </div>) : <div className='centered-text' style={{ fontSize: "40px", marginRight: "2rem" }}>Local Project</div>}
            </div>
        </div><div style={{ display: 'flex', justifyContent: 'center', margin: '30px 0' }}>
                <Button
                    style={{ backgroundColor: "#4CAF50", color: "#FFFFFF" }}
                    className="buttonStyle"
                    variant="contained"
                    size="large"
                    onClick={() => nav('/CreateUser')}
                >
                    Get Started Today!
                </Button>
            </div></>
    )
}