
import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Spinner = styled.div`
  background: radial-gradient(circle at 30% 50%, #8cc63f 20%, #619533 30%);
  border-radius: 50%;
  width: 50px;
  height: 65px; /* Adjust height to shape the apple */
  animation: ${spin} 1s linear infinite;
`;

const AppleStem = styled.div`
  position: relative;
  background: #885325;
  width: 8px;
  height: 20px;
  margin: 0 auto;
  border-radius: 50% 50% 0 0;
  top: -65px; /* Adjust top position to align with the apple */
`;

const Leaf = styled.div`
  position: relative;
  background: #619533;
  width: 20px;
  height: 25px;
  margin: 0 auto;
  border-radius: 0 0 50% 50%;
  top: -85px; /* Adjust top position to align with the apple */
  left: 6px; /* Adjust left position to align with the apple */
`;

const AppleSpinner = () => (
  <div id="leaves">
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i> 
</div>
  




);
export default AppleSpinner