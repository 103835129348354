import React from 'react';

const MetaData = () => {
  return (
    <>
      <meta name="description" content="Manage projects, add businesses to the map, find volunteer opportunities, write blogs, post tweets, and list job openings on our professional platform." />
      <meta name="keywords" content="project management, business mapping, volunteer opportunities, blogging, tweets, job postings, professional services" />
      <meta name="author" content="ProjectHammer @ GitHub" />
      <meta name="robots" content="index, follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </>
  );
};
export default MetaData;