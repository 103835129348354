import { useEffect, useState } from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tasks from './Tasks'
import TaskList from './TaskList';
import PdfUploader from './PdfUploader';
import Card from '@mui/material/Card';
import { CardContent } from '@mui/material';
import { useForm } from "react-hook-form";
import { TaskProvider } from './TaskContext';
import { createTask } from '../services/TaskService'
import { getAllTasks, deleteTask } from '../services/TaskService'
import { getInbox } from '../services/InboxService';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Button } from '@material-ui/core';

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const CreateTask = ({ user, imgUser, realUser, setUpdatedTasks }) => {
  const nav = useNavigate();
  if (realUser === null) {
    nav('/');
  }
  const { register, handleSubmit } = useForm();
  const [tasks, setTasks] = useState([]);
  //const [numberOfTasks, setNumberOfTasks] = useState([]);
  const [isTaskEdited, setTaskEdited] = useState(false);
  const [users, setUsers] = useState([]);
  const [isMember, setIsMember] = useState([]);
  const [addTask, setAddTask] = useState(false);
  const [removeTask, setRemoveTask] = useState([]);
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);
  const [queue, setQueue] = useState([]);

  const SelectTasks = ['To Be Done', 'In Progress', 'Completed']

  useEffect(() => {
    const processQueue = async (taskId) => {
      for (const action of queue) {
        // Execute the asynchronous action (e.g., delete item)
        await action();
      }
      // Clear the queue after processing
      setQueue([]);
    };
    // Process the queue whenever it's not empty
    if (queue.length > 0) {
      processQueue();
    }
  }, [queue]);

  const delTask = (taskId) => {
    enqueueAction(async () => {
      try {
        // deleteTask is an asynchronous function that returns a promise
        await deleteTasks(taskId);
        // Update state after successful deletion
        setRemoveTask(taskId);
        setTaskEdited(prevTaskEdited => !prevTaskEdited);
      } catch (error) {
        console.error('Error deleting task:', error);
      }
    });
  };

  const deleteTasks = async (taskId) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        deleteTask(taskId)
        setTaskEdited(prevTaskEdited => !prevTaskEdited);
        resolve();
      }, 1000); // Simulate a delay (1 second) for the asynchronous operation
    });
  };

  const CurrentUser = { user };
  useEffect(() => {
    getInbox(CurrentUser).then(inboxes => {
      const checkMembers = inboxes[0]?.['members'];
      if (checkMembers !== undefined) {
        const uniqueMembers = new Set(); // Create a Set to store unique members
        for (const key in checkMembers) {
          if (Object.hasOwnProperty.call(checkMembers, key)) {
            const name = checkMembers[key].name;
            const projectId = checkMembers[key].projectId;
            const isMember = checkMembers[key].isMember;
            const member = checkMembers[key].members;
            const data = { name: name, isMember: isMember, projectId: projectId, member: member };
            if (data.isMember === 1) {
              uniqueMembers.add(JSON.stringify(data)); // Add the data as a string to the Set
            }
          }
        }
        // Add the "CurrentUser" data to the Set
        const addUser = {
          name: CurrentUser.user,
          isMember: 1,
          projectId: "001",
          member: CurrentUser.user
        };
        uniqueMembers.add(JSON.stringify(addUser));
        // Convert the Set back to an array and update the state
        setIsMember(Array.from(uniqueMembers).map(JSON.parse));
      }
    });
    getAllTasks().then(tasks => {
      setTasks(tasks)
    });
  }, [addTask, removeTask, isTaskEdited]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const onSubmit = (data, e) => {
    const newData = { createdBy: user, status: data.status[1], task: data.task, assignee: data['assignee'][0].member, message: "", imgLink: imgUser };
    enqueueAction(async () => {
      try {
        const sendTask = await createTask(newData);
        if (sendTask) {
          setAddTask(prevTaskEdited => !prevTaskEdited);
          setTaskEdited(prevTaskEdited => !prevTaskEdited);
          setUpdatedTasks(prevTaskEdited => !prevTaskEdited);
        }
      } catch (error) {
        console.error('Error sending task:', error);
      }
    });
  };

  const enqueueAction = (action) => {
    setQueue(prevQueue => [...prevQueue, action]);
  };

  function taskEdited(res) {
    setTaskEdited(res)
  }

  function userCreated() {
    setUsers(users)
    setUsers(setUsers + 1)
  }

  return (
    <>
      <Card style={{ marginLeft: '0.1rem', marginRight: '0.1rem', maxWidth: "450px" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent item>
            <InputLabel id="task">New task</InputLabel>
            <TextField fullWidth style={{ marginTop: "5px" }} {...register("task")} className="form-control" required />
            <div hidden {...register("createdBy")} className="form-control" name="createdBy" id="createdBy" value={user} />
            <InputLabel style={{ marginTop: "5px" }} id="assigneee">Assignee</InputLabel>
            <Select sx={{marginBottom:"1rem"}}
              fullWidth     
              {...register("assignee")}
              required
              multiple
              value={personName}
              onChange={handleChange}
            >
              {isMember.map((name, index) => (
                <MenuItem
                  multiline={true}
                  key={index}
                  value={name}
                  style={getStyles(name, personName, theme)}
                >
                  {name.member}
                </MenuItem>
              ))}
            </Select>
            <InputLabel style={{ marginTop: "5px" }} htmlFor="exampleInputEmail1">Status:</InputLabel>
            <Select 
              fullWidth
              sx={{marginBottom:"1rem"}}
              {...register("status")}
              value={personName}
              multiple
              onChange={handleChange}
            >
              {SelectTasks.map((status, index) => (
                <MenuItem
                  multiline={true}
                  key={index}
                  value={status}
                  style={getStyles(status, personName, theme)}>
                  {status}
                </MenuItem>
              ))}
            </Select>
            <TaskProvider>
              <div>
                <PdfUploader hidden />
                <Button style={{ float: 'right' }} type="submit" variant="outlined">
              Add Task
            </Button>
                <TaskList />
              </div>
            </TaskProvider>
            <div style={{ marginTop: '60px' }}>
              <Tasks
                style={{ marginTop: '30px', marginBottom: '30px' }}
                tasks={tasks}
                user={user}
                delTask={delTask}
                userCreated={userCreated}
                setUpdatedTasks={setUpdatedTasks}
                taskEdited={taskEdited}
              />
            </div>
          </CardContent>
        </form>
      </Card>
    </>
  );
};
export default CreateTask;