import React, { useState, useRef, useEffect } from "react";
import { deleteImgFromProject } from "../services/InboxService";
export const ProjectPictures = ({ imgProject, projectName }) => {
  const [selectedImg, setSelectedImg] = useState(null);
  const [loadedImages, setLoadedImages] = useState([]);
  const [failedImages, setFailedImages] = useState([]);
  const imgWrapperRef = useRef(null);

  useEffect(() => {
    const loadedImages = [];
    const failedImages = [];
  
    const checkImageLoad = () => {
      const promises = imgProject.map((img) => {
        return new Promise((resolve) => {
          const image = new Image();
          image.src = img;
          image.onload = () => {
            loadedImages.push(img);
            resolve();
          };
          image.onerror = () => {
            failedImages.push(img);
            const data = { name: projectName, imgLink: img };
            deleteImgFromProject(data);
            resolve();
          };
        });
      });
  
      Promise.all(promises).then(() => {
        setLoadedImages(loadedImages);
        setFailedImages(failedImages);
      });
    };
  
    checkImageLoad();
  }, [imgProject, projectName]);

  const handleClick = (img) => {
    setSelectedImg(img);
  };

  const renderImages = () =>
    loadedImages.map((img, index) => (
      <div
        className="moveImg"
        key={index}
        style={{ position: "relative", overflow: "hidden" }}
      >
        <img
          src={img}
          alt={`project ${index}`}
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "lightgray",
            objectFit: "cover",
            cursor: "pointer",
          }}
          onClick={() => handleClick(img)}
        />
      </div>
    ));

  return (
    <div 
      ref={imgWrapperRef}
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
        gap: "5px",
        marginTop:"1rem"
      }}
    >
      {renderImages()}
      {selectedImg && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 5,
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 99999999,
          }}
        >
          <img
            src={selectedImg}
            alt="selected project"
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              objectFit: "contain",
            }}
            onClick={() => setSelectedImg(null)}
          />
        </div>
      )}
    </div>
  );
};
