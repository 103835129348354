import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Grid, MenuItem, Select, TextField, InputLabel } from '@mui/material';
import { useForm } from 'react-hook-form';
import { editTask } from '../services/TaskService';

export default function EditTaskModal({ tasks, taskEdited }) {
  const [open, setOpen] = useState(false);
  const { register, handleSubmit } = useForm();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data) => {
    editTask(data).then((response) => {
      taskEdited(response);
      handleClose();
    });
  };

  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen}>
        Edit
      </Button>
      <Dialog open={open} onClose={handleClose}>
        
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  hidden
                  variant="outlined"
                  fullWidth
                  label={`Assigned to: ${tasks.assignee}`}
                  InputProps={{ readOnly: true }}
                  {...register('_id')}
                  type="text"
                  defaultValue={tasks._id}
                  name="_id"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Task"
                  {...register('task')}
                  type="text"
                  defaultValue={tasks.task}
                  name="task"
                  placeholder="Create a Task"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Assignee"
                  InputProps={{ readOnly: true }}
                  {...register('assignee')}
                  type="text"
                  defaultValue={tasks.assignee}
                  name="assignee"
                  placeholder="Assignee"
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel>Status</InputLabel>
                <Select
                  variant="outlined"
                  fullWidth
                  label="Status"
                  {...register('status')}
                  name="status"
                  defaultValue={tasks.status}
                >
                  <MenuItem value="To Be Done">To Be Done</MenuItem>
                  <MenuItem value="In Progress">In Progress</MenuItem>
                  <MenuItem value="Completed">Completed</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Save
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}
