import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Link } from '@mui/material';
import ExploreIcon from '@mui/icons-material/Explore';
const BlogList = ({ blogsData }) => {
  return (
    <List component="nav" style={{maxWidth:"200px"}}>
      {blogsData.map((blogInfo, index) => (
        <ListItem key={index} button>
          <ListItemIcon>
         <ExploreIcon></ExploreIcon>
          </ListItemIcon>
          <Link
            href={`http://www.localproject.app/Blogs/${blogInfo.blogTitle}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItemText primary={blogInfo.blogTitle} />
          </Link>
        </ListItem>
      ))}
    </List>
  );
};
export default BlogList;