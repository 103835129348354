import React, { useState, useEffect, lazy, Suspense } from 'react';
import AppleSpinner from '../components/AppleSpinner';
import LocalChat from '../pages/LocalChats';
import TestimonialAvatar from '../components/TestimonialAvatar';
import MetaData from '../metaData/MetaData';
import Footer from '../components/Footer';
import { reviews } from '../data/NewsData';
import { HomePageHeader } from './HomePageHeader';
import WrapSection from './WrapSection';
const HomePageBar = lazy(() => import('../components/HomePageBar'));
const HomePageContent = lazy(() => import('./HomePageContent'));

const HomePage = () => {
  const [currentReview, setCurrentReview] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const quote = "\"Simplicity is the ultimate sophistication. - Leonardo da Vinci\"";

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentReview((currentReview + 1) % reviews.length);
    }, 9999);
    
    // Update the screenWidth state when the window is resized
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      clearInterval(timer);
      window.removeEventListener('resize', handleResize);
    };
  }, [currentReview]);

  const hideTestimonialOnSmallScreens = screenWidth > 450;
  return (
      <Suspense fallback={<div><AppleSpinner></AppleSpinner></div>}>
        <MetaData />
        <HomePageBar />
        
        
        <HomePageHeader hideTestimonialOnSmallScreens={hideTestimonialOnSmallScreens} />
        <LocalChat />
        <WrapSection quote={quote}></WrapSection>
        <HomePageContent  />
       
        {hideTestimonialOnSmallScreens ? (
          // Add the 'hide-testimonial' class to hide the testimonial on smaller screens
          <TestimonialAvatar
            key={currentReview}
            name={reviews[currentReview].name}
            title={reviews[currentReview].title}
            image={reviews[currentReview].image}
            className={`active ${hideTestimonialOnSmallScreens ? 'hide-testimonial' : ''}`}
          />
        ) : null}
       
        <Footer />
      </Suspense>
  );
};
export default HomePage;