import React, { useEffect, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import PhotoIcon from '@mui/icons-material/Photo';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import { onImageChange } from './UploadFiles';
import Swal, { } from 'sweetalert2/dist/sweetalert2.js'
import { getOnlineUsers, createTweet } from '../services/OnlineService';
import { getInbox } from '../services/InboxService';
import { useNavigate } from 'react-router-dom';
const Twitters = ({realUser}) => {
  const [isTweetdOpen, setIsTweetOpen] = useState(false);
  const [tweetData, setTweetData] = useState([]);
  const [message, setMessage] = useState('');
  const [tweetAvatar, setTweetAvatar] = useState(''); // Initialize as a string
  const [image, setImage] = useState(''); // Initialize as a string
  const [characterCount, setCharacterCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isDateSet, setIsDateSet] = useState(false);
  const user = realUser;

  useEffect(() => {
    getTweets()
    
  }, [isDateSet])

  useEffect(() => {
    getTweetAvatar()
    
  }, [])

  const nav = useNavigate()
  const goToUserProfile = (e) => {
    const userId = e.currentTarget.id;
    nav(`/UserProjects/${userId}`, { state: { user: e.currentTarget.id, realUser } });
  }

  const getTweetAvatar = async () => {
    try {
      const newTweetUser = { user };
      const inboxData = await getInbox(newTweetUser);
      if (inboxData.length > 0) {
        const [firstImage] = inboxData[0].userImg;
        setTweetAvatar(firstImage.imgLink);
      } else {
        // Handle the case when inboxData is empty or doesn't contain the expected data.
        console.error('InboxData is empty or missing userImg');
      }
    } catch (error) {
      console.error('Error fetching Tweet Avatar:', error);
      throw new Error('Error fetching Tweet Avatar');
    }
  };
  const getTweets = async () => {
    try {
      // Assuming getOnlineUsers() is an async function that fetches job data
      const tweetDataResponse = await getOnlineUsers();
      // Assuming jobDataResponse[0].jobs is an object where each key represents a job
      const newTweetData = tweetDataResponse[0].tweets; 
      const rows = Object.keys(newTweetData.reverse()).map(key => {
        const { user, message, img, date, hashtag } = newTweetData[key];
        // Return an object with specific job properties
        return {
          user,
          message,
          img,
          date,
          hashtag,
        };
      });
      // Update the state with the new job data
      setTweetData(rows);
    } catch (error) {
      console.error('Error fetching jobs:', error);
      throw new Error('Error fetching jobs');
    }
  };
  const handleOpenTweet = () => {
    setIsTweetOpen(true);
  };
  const handleCloseTweet = () => {
    setIsTweetOpen(false);
  };
  const handleInputChange = (e) => {
    const inputMessage = e.target.value;
    setMessage(inputMessage);
    setCharacterCount(inputMessage.length);
  };
  const handleImageUpload = async (e) => {
    if(realUser === undefined){
      Swal.fire({
        title: 'Please Login',
        zIndex: 500, // Adjust the zIndex value as needed
      });
    }else{
      try {
        setIsLoading(true)
        const selectedImage = e.target.files[0];
        const uploadFile = await onImageChange(selectedImage, realUser, setImage);
        return await uploadFile.json();
      } catch (error) {
        // Handle any errors and set isLoading to false
        console.error('Error during image upload:', error);
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  };
  const calculateTimeAgo = (eventDateTime) => {
    const currentDateTime = new Date();
    const eventDate = new Date(eventDateTime); // Parse the string into a Date object
    const timeDifference = currentDateTime.getTime() - eventDate.getTime();
    const secondsAgo = Math.floor(timeDifference / 1000);
    const minutesAgo = Math.floor(secondsAgo / 60);
    const hoursAgo = Math.floor(minutesAgo / 60);
    const daysAgo = Math.floor(hoursAgo / 24);
    if (daysAgo > 0) {
      return `${daysAgo} day${daysAgo !== 1 ? 's' : ''} ago`;
    } else if (hoursAgo > 0) {
      return `${hoursAgo} hour${hoursAgo !== 1 ? 's' : ''} ago`;
    } else if (minutesAgo > 0) {
      return `${minutesAgo} minute${minutesAgo !== 1 ? 's' : ''} ago`;
    } else {
      return `${secondsAgo} second${secondsAgo !== 1 ? 's' : ''} ago`;
    }
  }
  async function createAndSendTweet() {
    if(realUser === undefined){
      Swal.fire({
        title: 'Please Login',
        zIndex: 600, // Adjust the zIndex value as needed
      });
    }else{
    setIsLoading(true)
    const tweetNewData = {
      user: realUser,
      message: message,
      img: image,
      date: new Date().toISOString(), 
      hashtag: tweetAvatar,
      replies: [
        {
          user: "",
          message: ""
        }
      ],
      likes: [
        {
          user: "LikeUser",
          counter: "1"
        }
      ]
    };
    const result = await createTweet(tweetNewData);
    if (result) {
      setIsLoading(false);
      handleCloseTweet()
      Swal.fire({
        title: 'Tweet Sent',
        zIndex: 600, // Adjust the zIndex value as needed
      });
      setIsDateSet(true)
    } else {
      setIsLoading(false);
      handleCloseTweet()
      Swal.fre("Tweet Error Retry")
    }
  }
  }
  let messageUpTo50 = message.slice(0, 200);
  let messageOver50 = message.slice(200);
  return (
    <>
<div  style={{ marginLeft:"1rem",position: 'relative', textAlign: 'center' }}>
     <IconButton style={{ position: 'absolute', top: -20, zIndex:"200", left: '50%', transform: 'translateX(-50%)' }} onClick={handleOpenTweet}>
          <AddCircleIcon />
        </IconButton>
        {isTweetdOpen && (
          <Card  sx={{   position: 'fixed',
          borderRadius: '10px',
          maxWidth: '400px',
          zIndex:"500",
          margin: '1 auto',
          top: '8rem', // Set it at the top
          left: '50%', // Center horizontally
          transform: 'translateX(-50%)', // Center horizontally
          textAlign: 'left' }}>
            <IconButton
              style={{
                zIndex:"23",
                position: 'absolute',
                color: 'black',
                top: '0.5rem',
                right: '0.5rem',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
              }}
              onClick={() => {
                setMessage('');
                setImage('');
                setCharacterCount(0);
              }}
            >
              <CloseIcon onClick={handleCloseTweet} style={{ color: 'black' }} />
            </IconButton>
            <CardContent style={{minWidth:"400px"}}>
              <div className="tweet-wrap">
                <div style={{ display: 'flex' }} className="tweet-header">
                  <Avatar src={tweetAvatar} style={{ marginTop: '0.4rem' }} className="avator">
                    {tweetAvatar}
                  </Avatar>
                  <div style={{ display: 'flex', marginTop: '0.8rem', marginLeft: '0.5rem' }} className="tweet-header-info">
                    <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold' }}>
                    {realUser !== undefined ? realUser : '@Guest'}
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: '0.26rem', color: '#657786', fontWeight: 'normal', marginLeft: '0.5rem' }}>
                    {realUser !== undefined ? realUser : '@Guest'}
                    </Typography>
                  </div>
                </div>
                <div className="image-container" style={{ position: 'relative' }}>
                  {image && (
                    <div>
                      <div>
                        <IconButton
                          onClick={() => {
                            setImage('');
                          }}
                          style={{
                            position: 'absolute',
                            color: 'black',
                            top: '0.8rem',
                            right: '0.3rem',
                            zIndex: '999',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                          }}
                          className="close-button"
                        >
                          <CloseIcon sx={{ fontSize: 20 }} />
                        </IconButton>
                        <img
                          src={image}
                          alt="Selected"
                          style={{
                            marginTop: '0.5rem',
                            maxWidth: '100%',
                            maxHeight: '400px',
                            padding: '1px',
                            borderRadius: '1px',
                            display: 'inline-block'
                          }} />
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  {messageUpTo50.length > 0 && (
                    <div>
                      <Typography mt={1} variant="h6" color="textSecondary" className="break-words">
                        {messageUpTo50.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </Typography>
                    </div>
                  )}
                  {messageOver50.length > 0 && (
                    <div>
                      <Typography mt={1} variant="h6" color="textSecondary" className="break-words" style={{ color: 'red' }}>
                        {messageOver50.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </Typography>
                    </div>
                  )}
                </div>
                <div>
                </div>
                <div className="tweet-img-wrap">
                  <TextField
                    style={{ marginTop: '1rem', border: 'none' }}
                    placeholder='Whats happening?'
                    value={message}
                    onChange={handleInputChange}
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <div style={{ minWidth: "4.5rem", display: 'flex', alignItems: 'center', marginTop: '-4.3rem', marginRight: "-0.7rem" }}>
                          <p style={{ color: characterCount > 200 ? 'red' : 'inherit' }}>
                            {characterCount} / 200
                          </p>
                        </div>
                      ),
                    }}
                    multiline
                    rows={3}
                    variant="filled" />
                  <input
                    type="file"
                    style={{ display: 'none', objectFit: 'cover' }}
                    accept="image/*"
                    encType='multipart/form-data'
                    onChange={handleImageUpload}
                    id="image-upload" />
                  <Divider className="buttonStyle" style={{ marginBottom: '1rem' }} />
                  <div style={{ display: 'flex' }}>
                    <label htmlFor="image-upload">
                      <Divider></Divider>
                      <IconButton color="primary" component="span" disabled={isLoading}>
                        <PhotoIcon />
                      </IconButton>
                    </label>
                    <IconButton
                      color="primary"
                      onClick={createAndSendTweet}
                      disabled={message === '' || message.length > 200 || isLoading}
                    >
                      {isLoading ? <CircularProgress size={24} /> : <SendIcon />}
                    </IconButton>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        )}
 <div>
{tweetData.map((tweetInfo, index) => (
    <Card key={index} sx={{ position: 'relative', borderRadius: '10px', maxwidth: '25rem', margin: '1 auto', marginTop: '1rem', textAlign: 'left' }}>
      <CardContent>
        <div className="tweet-wrap">
          <div onClick={goToUserProfile} id={tweetInfo.user} style={{ display: 'flex', cursor: "pointer"}} className="tweet-header">
            <Avatar src={tweetInfo.hashtag} style={{ marginTop: '0.4rem' }} className="avator">
              {tweetInfo.hashtag}
            </Avatar>
            <div style={{ display: 'flex', marginTop: '0.8rem', marginLeft: '0.5rem' }} className="tweet-header-info">
              <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold' }}>
              {tweetInfo.user}
              </Typography>
              <Typography variant="body2" sx={{ marginTop: '0.26rem', color: '#657786', fontWeight: 'normal', marginLeft: '0.5rem' }}>
                @{tweetInfo.user}
              </Typography>
            </div>
          </div>
          <div className="image-container" style={{ position: 'relative' }}>
            {tweetInfo.img && (
              <div>
                <div>
                  <img 
                    src={tweetInfo.img}
                    alt="Selected"
                    style={{
                      marginTop: '0.5rem',
                      maxWidth: '100%',
                      maxHeight: '400px',
                      padding: '1px',
                      borderRadius: '1px',
                      display: 'inline-block'
                    }} />
                </div>
              </div>
            )}
          </div>
          <div>
            {tweetInfo.message.length > 0 && (
              <div>
                <Typography mt={1} variant="h6" color="textSecondary" className="break-words">
                  {tweetInfo.message.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </Typography>
              </div>
            )}
          </div>
          <div>
            <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold', margin: '0.5rem 0 0 0' }}>
              {calculateTimeAgo(tweetInfo.date)}
            </Typography>
            <div style={{ display: 'flex', size: '10%' }} className="tweet-info-counts">
              <div>
                <IconButton disabled>
                  <FavoriteIcon sx={{ fontSize: 15 }} />
                  <Typography ml={1} variant="body1" color="textSecondary">0</Typography>
                </IconButton>
              </div>
              <div>
                <IconButton disabled>
                  <ChatBubbleIcon  sx={{ fontSize: 15,marginLeft:"1rem" }} />
                  <Typography ml={1}  variant="body1" color="textSecondary">0</Typography>
                </IconButton>
              </div>
            </div>
          </div>
          </div>
        </CardContent>
    </Card> ))}
      </div>
      </div>
      </>
  );
};
export default Twitters