export async function getAllTasks() {
    const response = await fetch('https://localprojectapi-api.vercel.app/api/tasks');
    return await response.json();
}

export async function createTask(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/task`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({task: data})
    })
    return await response.json();
}

export async function deleteTask(taskId) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/task/${taskId}`, {method: 'DELETE'})
    return await response.json();
}

export async function getUserTasks(user) {
    try {
      const response = await fetch(`https://localprojectapi-api.vercel.app/api/getUserTasks/${user}`, {
        method: 'GET'
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error('Error in getUserTasks:', error);
      throw error; // Re-throw the error for the caller to handle if needed
    }
  }

  export async function editTask(data) {
    try {
      const response = await fetch('https://localprojectapi-api.vercel.app/api/updateTask', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ task: data })
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error('Error in editTask:', error);
      throw error; // Re-throw the error for the caller to handle if needed
    }
  }
export async function fetchSettings() {
    const response = await fetch('/api/settings');
    return await response.json();
}
