import React from 'react';
import { Container, Grid, Typography, Link, makeStyles } from '@material-ui/core';
import GitHubIcon from '@mui/icons-material/GitHub';

const useStyles = makeStyles((theme) => ({
  footer: {
    position: 'relative',
    bottom: 0,
    width: '100%',
    backgroundColor: 'whitesmoke',
    color: 'black', // Change the text color
    fontSize: '80%',
    padding: theme.spacing(2),
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer} style={{marginTop:"1rem", height:"250px",backgroundColor:"#e4f3e0"}}>
      <Container style={{height:"250px",backgroundColor:"#e4f3e0"}}>
        <Grid container justifyContent="center" alignItems="center">
          <Typography style={{color:"#444444"}}>
            <strong>
              Local Projects by{' '}
              <Link href="https://github.com/ProjectHammer" color="inherit">
                <GitHubIcon /> GitHub
              </Link>
              . The source code is licensed{' '}
              <Link href="https://localproject.app" color="inherit">
                by localproject.app
              </Link>
              . The website content is licensed{' '}
              <Link href="https://localproject.app" color="inherit">
                localproject.app
              </Link>
              .
            </strong>
          </Typography>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
