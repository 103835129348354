import React, { useRef, useState } from 'react';
import { Button } from '@material-ui/core';


const RecordVideo = () => {
  const videoRef = useRef(null);
  const [recordedVideoBlob, setRecordedVideoBlob] = useState(null);
  const [isRecording, setIsRecording] = useState(false);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      videoRef.current.srcObject = stream;
      videoRef.current.play();

      const mediaRecorder = new MediaRecorder(stream);
      const chunks = [];

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'video/webm' });
        setRecordedVideoBlob(blob);
      };

      mediaRecorder.start();
      setIsRecording(true);

      setTimeout(() => {
        mediaRecorder.stop();
        setIsRecording(false);
      }, 5000);  // Record for 5 seconds (adjust as needed)
    } catch (error) {
      console.error('Error capturing video:', error);
    }
  };

  return (
    <div>
      <Button onClick={startRecording} disabled={isRecording}>
        {isRecording ? 'Recording...' : 'Start Recording Video'}
      </Button>
      {recordedVideoBlob && (
        <video controls width="400" height="480" src={URL.createObjectURL(recordedVideoBlob)} autoPlay></video>
      )}
      <video ref={videoRef} width="400" height="480" autoPlay></video>
    </div>
  );
};

export default RecordVideo;