import { getKeys } from '../services/UserService';
import React, { useEffect } from "react"
import { useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import HomePage from "../homePage/HomePage";
import Button from '@mui/material/Button';
import { useParams, useNavigate } from 'react-router-dom';
import { updateUserPassword } from '../services/UserService';

export default function ResetPassWord() {
    const { register, handleSubmit } = useForm();
    const { userId } = useParams();
    const { email } = useParams();
    const nav = useNavigate();

    const onSubmit = async (data, e) => {
      let theKey = [];
      const newData = {email}      
      await getKeys(newData, theKey);
      let newKeyValue = theKey[0].theKey[0].key;
      const newStr = newKeyValue.replace(/\//g, '');
      
       data = { ...data, email };
      if(newStr === userId){ 
       
       await updateUserPassword(data)
        nav('/Login')
      }
    }
     
    const resetForm = () => {
        document.getElementById('formId').reset();
    }

useEffect(()=>{
        let x = document.getElementById('photoBlog');
        x.style.opacity = 0.2;
});
    return (
        <><HomePage></HomePage>
            <div class="subContainer" style={{top:"400px"}}>
            <div className='box' style={{maxWidth:"300px",minWidth:"300px" }}>
            <div className=' createQuestionPic' style={{height:"200px"}}></div>
                    <form id="formId"   onSubmit={handleSubmit(onSubmit)}>                 
                        <TextField style={{ marginTop:"5px",maxWidth:"100%" }} className="form-control" label="Enter Name" variant="outlined" {...register("to_name")} type="text" name="to_name" id="to_name" value="ToMe" hidden placeholder="Enter Name..." />
                        <TextField style={{ marginTop:"20px",maxWidth:"100%" }} className="form-control" label="Password" variant="outlined" {...register("password")} required name="password" id="password" type="text" placeholder="Enter new password" />
                        <Button style={{marginTop:"10px"}} className="buttonStyle" variant="contained"  type="submit">Confirm</Button>
                        <Button style={{marginTop:"10px",float:"right"}} className="buttonStyle" variant="contained"  to={'/Login'} onClick={resetForm}>Del</Button>
                    </form>
                    </div>
            </div>
        </>
    )
}
