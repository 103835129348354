import React from 'react';
import HomePageBar from '../components/HomePageBar';
import Grid from '@mui/material/Grid';
import Twitters from '../components/Twitters';
const Tweets = () => {
  return (
    <><HomePageBar /><div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
          <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center' }}>
              <Grid item xs={5} sm={6} md={4} lg={3}>
                  <Twitters />
              </Grid>
          </Grid>
      </div></>
  );
};
export default Tweets;