import React, { useContext, useState } from 'react';
import { TaskContext } from './TaskContext';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    mt:1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
const PdfUploader = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const { tasks, updatePdfStatus } = useContext(TaskContext);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [pdfLink, setPdfLink] = useState("");
  const upLoadPdf = async (taskId) => {
    
    const formData = new FormData();
    if (selectedFile && selectedFile.type === 'application/pdf') {
      formData.append("file", selectedFile);
      formData.append("filename", selectedFile.name);
      formData.append("type", selectedFile.type);
      formData.append("size", selectedFile.size);
      formData.append("lastModified", selectedFile.lastModified);
      formData.append("metadata", "metadata");
      // Assuming task ID is attached to the PDF
      formData.append("taskId", taskId);
      
      try {
        const response = await fetch('https://localprojectapi-api.vercel.app/file/upload', {
          method: 'POST',
          body: formData
        });

        if (response.ok) {
          const success = await response.json();
          setPdfLink(success);
        } else {
          console.error('Error uploading PDF:', response.statusText);
        }
      } catch (error) {
        console.error('Error uploading PDF:', error);
      }
    } else {
      console.error('Invalid PDF file.');
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setFileUploaded(true);
  };

  const attachToTask = (taskId) => {
    updatePdfStatus(taskId, true);
    upLoadPdf(taskId);
  };

  return (
    <div>
        <Button  component="label" variant="contained" type="file" accept=".pdf" onChange={handleFileChange} startIcon={<CloudUploadIcon />}>
  Upload file
  <VisuallyHiddenInput type="file" />
</Button>
     
      <div style={{ marginTop: '20px' }}>
        {selectedFile ? (
          <embed src={URL.createObjectURL(selectedFile)} width="100%" height="500px" />
        ) : (
          <p>No File selected</p>
        )}
      </div>
      {tasks.map((task) => (
        <div key={task.id}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '10px',marginBottom:"5px" }}
            onClick={() => attachToTask(task.id)}
            disabled={!selectedFile}  // Disable the button if no file is selected
          >
            Attach {task.id}
          </Button>
        </div>
      ))}
    </div>
  );
};

export default PdfUploader;