import LogoutIcon from '@mui/icons-material/Logout'
import React, { useEffect, useState } from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import EventIcon from '@mui/icons-material/Event'
import AppBar from '@mui/material/AppBar'
import Badge from '@mui/material/Badge'
import Toolbar from '@mui/material/Toolbar'
import EditIcon from '@mui/icons-material/Edit'
import DashboardIcon from '@mui/icons-material/Dashboard';
import ButtonGroup from '@mui/material/ButtonGroup'
import SimpleSlide from './SimpleSlide'
import MenuIcon from '@mui/icons-material/Menu'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import EngineeringIcon from '@mui/icons-material/Engineering'
import MailIcon from '@mui/icons-material/Mail'
import ConstructionIcon from '@mui/icons-material/Construction'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import WorkIcon from '@mui/icons-material/Work'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import ThreePIcon from '@mui/icons-material/ThreeP'
import SearchSkills from './SearchSkills'
import { useNavigate } from 'react-router-dom'
import CreateProject from './CreateProject'
const LoginBarTop = ({ realUser }) => {
  const user = realUser;
  const [messages, ] = useState(Number)
  const [projects, ] = useState(Number)
  const [members, ] = useState(Number)
  const [friends, ] = useState(Number)
  const [anchorElNav, setAnchorElNav] = useState(null)
  const [createProject, setCreateProject] = useState(false);
  const [tasks, ] = useState(0, Number)
  const [showButton, setShowButton] = useState(false)
  const nav = useNavigate()

  const routePage = (route, realUser) => {
    if(route === "CreateProject"){
      setCreateProject(true)
    }else{
      nav(`/${route}`, { state: { realUser,user } })
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 400) {
        setShowButton(true)
      } else {
        setShowButton(false)
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  },[]);
  
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  return (
    <><AppBar position="static" style={{ backgroundColor: "#e4f3e0" }} >
      <Toolbar disableGutters style={{ height: "100px", minHeight: "100px", maxHeight: "100px" }}>
        <Button sx={{ color:"#444444",marginTop: "10px", marginLeft: "5px", marginRight: "5px", borderTop: '1px solid white' }} variant="text"  onClick={() => routePage("Home", realUser)} style={{ marginBlock: "20px", float: "left",color: "#444444" }}><DashboardIcon label="Home" /></Button>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', backgroundColor: "transparent" } }}>
          <div
            onClick={handleCloseNavMenu}
            sx={{ my: 2, backgroundColor: 'transparent', display: 'block' }}>
            <Button sx={{ color:"#444444",width: "120px", marginTop: "10px", left: "0px", margin: "5px", borderTop: '1px solid white' }}  variant="text" onClick={() => routePage("CheckInbox", realUser)}><Badge badgeContent={messages}>
              <MailIcon color="white" />
            </Badge>&nbsp;&nbsp;Inbox</Button>
            <Button sx={{color:"#444444", width: "140px", marginTop: "10px", left: "0px", margin: "5px", borderTop: '1px solid white' }} variant="text"  onClick={() => routePage("CreateVolunteer", realUser)}><Badge badgeContent={""}>
              <EngineeringIcon color="white" />
            </Badge>&nbsp;&nbsp;Volunteer</Button>
            <Button sx={{ color:"#444444",width: "120px", marginTop: "10px", left: "0px", margin: "5px", borderTop: '1px solid white' }} variant="text"  onClick={() => routePage("CreateProject", realUser)}><ConstructionIcon /><span>&nbsp;&nbsp;</span>Create</Button>
            <Button sx={{ color:"#444444",width: "120px", marginTop: "10px", left: "0px", margin: "5px", borderTop: '1px solid white' }}  variant="text" onClick={() => routePage("UserProjects", realUser)}><Badge badgeContent={projects} color="primary">
              <ConstructionIcon color="white" />
            </Badge><span>&nbsp;&nbsp;</span>Projects</Button>
            <Button sx={{ color:"#444444",width: "120px", marginTop: "10px", left: "0px", margin: "5px", borderTop: '1px solid white' }}  variant="text" onClick={() => routePage("UploadImages", realUser)}><AddAPhotoIcon /><span>&nbsp;&nbsp;</span>Photos</Button>
            <Button sx={{ color:"#444444",width: "120px", marginTop: "10px", left: "0px", margin: "5px", borderTop: '1px solid white' }}  variant="text" onClick={() => routePage("UserTasks", realUser)}><Badge badgeContent={tasks} color="primary">
              <EngineeringIcon color="white" />
            </Badge><span>&nbsp;&nbsp;</span> Tasks</Button>
            <Button sx={{color:"#444444", width: "120px", marginTop: "10px", left: "0px", margin: "5px", borderTop: '1px solid white' }}  variant="text" onClick={() => routePage("Jobs", realUser)}><WorkIcon /><span>&nbsp;&nbsp;</span>&nbsp;Jobs</Button>        
            <Button sx={{ color:"#444444",width: "120px", marginTop: "10px", left: "0px", margin: "5px", borderTop: '1px solid white' }}  variant="text" onClick={() => routePage("Blogs", realUser)}><WorkIcon /><span>&nbsp;&nbsp;</span>&nbsp;Blogs</Button>
            <Button sx={{ color:"#444444",width: "120px", marginTop: "10px", left: "0px", margin: "5px", borderTop: '1px solid white' }}  variant="text" onClick={() => routePage("MemberRequest", realUser)}><Badge badgeContent={members} color="primary">
              <ThreePIcon color="#444444" />
            </Badge>
              <span>&nbsp;&nbsp;</span>Members</Button>
            <Button sx={{ color:"#444444", width: "120px", marginTop: "10px", left: "0px", margin: "5px", borderTop: '1px solid white' }}  variant="text" onClick={() => routePage("AcceptFriendsRequest", realUser)}><Badge badgeContent={friends} color="primary">
              <PersonAddIcon color="#444444" />
            </Badge><span>&nbsp;&nbsp;</span> Friends </Button>
            <Button sx={{ color:"#444444",width: "120px", marginTop: "10px", margin: "5px", borderTop: '1px solid white' }}  variant="text"  onClick={() => routePage("UserInfo", realUser)}><EditIcon />&nbsp;Profile</Button>
            <Button sx={{color:"#444444", width: "120px", marginTop: "10px", margin: "5px", borderTop: '1px solid white' }}  variant="text"  onClick={() => routePage("Calendar", realUser)}><EventIcon />&nbsp;Calendar</Button>
          </div>
        </Box>
        <SearchSkills realUser={realUser}></SearchSkills>
        <Button sx={{color:"#444444", marginRight: "0.1rem", marginLeft: '0rem'}} variant="text"  onClick={() => routePage("", realUser)}><LogoutIcon></LogoutIcon></Button>
        <Box sx={{ display: {  md: 'none', marginRight: "5px",marginLeft:"auto" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="blue">
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            <ButtonGroup orientation="vertical" 
            aria-label="vertical outlined button group" onClick={handleCloseNavMenu} >
              <Button style={{ color: "#444444", marginTop:"-8px",backgroundColor:"#e4f3e0" }} variant="contained" className="buttonStyle" onClick={() => routePage("CheckInbox", realUser)}><Badge badgeContent={messages}>
                <MailIcon color="white" />
              </Badge>&nbsp;&nbsp;Inbox</Button>
              <Button style={{ color: "#444444",marginTop:"5px",backgroundColor:"#e4f3e0" }} variant="contained" className="buttonStyle" onClick={() => routePage("CreateVolunteer", realUser)}><ConstructionIcon /><span>&nbsp;&nbsp;</span>Vol</Button>
              <Button style={{ color: "#444444",marginTop:"5px",backgroundColor:"#e4f3e0" }} variant="contained" className="buttonStyle" onClick={() => routePage("CreateProject", realUser)}><ConstructionIcon /><span>&nbsp;&nbsp;</span>Create</Button>
              <Button style={{ color: "#444444",marginTop:"5px",backgroundColor:"#e4f3e0" }} variant="contained" className="buttonStyle" onClick={() => routePage("UserProjects", realUser)}><Badge badgeContent={projects} color="primary">
                <ConstructionIcon color="#444444" />
              </Badge><span>&nbsp;&nbsp;</span>Projects</Button>
              <Button style={{ color: "#444444",marginTop:"5px",backgroundColor:"#e4f3e0" }} variant="contained" className="buttonStyle" onClick={() => routePage("UploadImages", realUser)}><AddAPhotoIcon /><span>&nbsp;&nbsp;</span>Photos</Button>
              <Button style={{ color: "#444444",marginTop:"5px",backgroundColor:"#e4f3e0" }} variant="contained" className="buttonStyle" onClick={() => routePage("UserTasks", realUser)}><Badge badgeContent={tasks} color="primary">
                <EngineeringIcon color="#444444" />
              </Badge><span>&nbsp;&nbsp;</span> Tasks</Button>
              <Button style={{ color: "#444444",marginTop:"5px",backgroundColor:"#e4f3e0" }} variant="contained" className="buttonStyle" onClick={() => routePage("Jobs", realUser)}><WorkIcon /><span>&nbsp;&nbsp;</span>&nbsp;Jobs</Button>
              <Button style={{ color: "#444444",marginTop:"5px",backgroundColor:"#e4f3e0" }} variant="contained" className="buttonStyle" onClick={() => routePage("MemberRequest", realUser)}><Badge badgeContent={members} color="primary">
                <ThreePIcon color="#444444" />
              </Badge>
                <span>&nbsp;&nbsp;</span>Members</Button>
              <Button style={{ color: "#444444",marginTop:"5px",backgroundColor:"#e4f3e0" }} variant="contained" className="buttonStyle" onClick={() => routePage("AcceptFriendsRequest", realUser)}><Badge badgeContent={friends} color="primary">
                <PersonAddIcon color="#444444" />
              </Badge><span>&nbsp;&nbsp;</span> Friends </Button>
              <Button style={{ color: "#444444", marginTop:"5px",backgroundColor:"#e4f3e0" }} variant="contained" className="buttonStyle" onClick={() => routePage("UserInfo", realUser)}><EditIcon />&nbsp;Profile</Button>
              <Button style={{ color: "#444444", marginTop:"5px",marginBottom:"-8px",backgroundColor:"#e4f3e0"  }} variant="contained" className="buttonStyle" onClick={() => routePage("Calendar", realUser)}><EventIcon />&nbsp;Calendar</Button>
            </ButtonGroup>
          </Menu>
        </Box>
      </Toolbar>
      {showButton && (
        <Button sx={{ bottom: "70px", position: "fixed", zindex: "1000", marginLeft: "5px", borderTop: '1px solid white' }} variant="contained" className="buttonStyle" value="home" onClick={scrollTop}>
          <ArrowUpwardIcon label="white" /></Button>
      )}
    </AppBar>
    <SimpleSlide  realUser={realUser} style={{ zIndex: 300 }} />
     {createProject && <CreateProject realUser={user} createProject={createProject} />}
    </>
  )
}
export default LoginBarTop