import React, { } from "react";
import { useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import HomePageBar from "../components/HomePageBar";
import { Textarea } from '@mui/joy';
import emailjs from 'emailjs-com';
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button';
import Swal from 'sweetalert2';
import Footer from "../components/Footer";
export default function ContactMe() {
    const { register, handleSubmit } = useForm();
    const onSubmit = async (data, e) => {
        const serviceID = "service_dwqv1s8";
        const templateID = "template_dgv94nd";
        const publicKey = "Bl0ezucwaL6MgmwxH";
        await emailjs.send(serviceID, templateID, data, publicKey)
            .then(function (response) {
                console.log('SUCCESS!', response.status, response.text);
                Swal.fire(
                    'Message Sent',
                    'We will get back to you shortly',
                    'success'
                );
            }, function (error) {
                console.log('FAILED...', error);
            });

        e.target.reset();
    };
    const resetForm = () => {
        document.getElementById('formId').reset();
    };
    return (
        <>
        <HomePageBar></HomePageBar>
        <Card sx={{ maxWidth: "350px", justifyContent: "center", marginLeft: "auto", marginRight: "auto",marginBottom:"350px", marginTop:"3rem"}}>
            <CardMedia className='imgLogin'
                  component="Img3"
                  alt="Login Pic"
                  style={{ objectFit: "cover", height: '15rem', marginBottom: "1.8rem", marginTop: "1.8rem" }}
                />   
                          <form  id="formId" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent sx={{  }} >
                        <div label="Enter Name"  {...register("to_name")} type="text" name="to_name" id="to_name" value="ToMe"  placeholder="Enter Name..." />
                        <TextField  fullWidth style={{ marginTop: "1rem" }} label="Username" {...register("from_name")} required name="from_name" id="from_name" type="text" placeholder="Username" />
                        <TextField fullWidth style={{ marginTop: "1rem" }} label="Email"  {...register("reply_to")} required name="reply_to" id="reply_to" type="email" placeholder="Email" />
                        <Textarea fullWidth minRows={2} style={{ marginTop: "1rem" }} required label="Message"  {...register("message")} name="message" id="message" placeholder="Message" />
                        <Button fullWidth style={{ backgroundColor: "#e4f3e0", color: "#444444", marginTop: "1rem"  }} variant="contained" type="submit">Submit</Button>
                        <Button fullWidth style={{backgroundColor: "#e4f3e0", color: "#444444", marginTop: "1rem", marginBottom:"1rem", float: "right" }}  variant="contained" onClick={resetForm}>Clear</Button>
                </CardContent>
                </form>
            </Card>
          <Footer></Footer>
        </>
    )
}