import React, { useContext } from 'react';
import { TaskContext } from './TaskContext';

const TaskList = () => {
  const { tasks } = useContext(TaskContext);
  return (
    <div>
      <ul>
        {tasks.map((task) => (
          <li key={task.id}>
            {task.title} - {task.description}
            {task.pdfUploaded ? (
              <span style={{ marginLeft: '10px' }}>File Uploaded</span>
            ) : (
              <span style={{ marginLeft: '10px' }}>No File</span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TaskList;
