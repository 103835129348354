import React, { useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
//import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import LoadingButton from '@mui/lab/LoadingButton'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import SendIcon from '@mui/icons-material/Send'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import PersonIcon from '@mui/icons-material/Person'
import Routing from '../Routing'
import process from 'process';
import HomePageBar from '../components/HomePageBar'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import { useForm } from "react-hook-form"
import { getLogin } from '../services/UserService'
import { useNavigate, useLocation } from 'react-router-dom'
import Typography from '@mui/material/Typography';
import { createKey } from '../services/UserService'
import { getKey } from '../services/UserService'
import { Button } from '@material-ui/core'
import Footer from '../components/Footer'

const LoginPage = () => {
    const location = useLocation();
    const nav = useNavigate();
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit } = useForm()
    const [isLoggedIn, setIsLoggedIn] = useState({ username: '', password: '', showPassword: false, })
    const gotoCreate = () => { nav('/CreateUser') }
    //const closeLoginBox = () => { nav('/') }
    const gotoPasswordRecovery = () => { nav('/PasswordRecoveryForm') }
    const apiKey = process.env.REACT_APP_API_KEY;

    const handleChange = (prop) => (event) => {
        setIsLoggedIn({ ...isLoggedIn, [prop]: event.target.value })
    };

    const handleClickShowPassword = () => {
        setIsLoggedIn({
            ...isLoggedIn,
            showPassword: !isLoggedIn.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    };

    const handleLogin = (realUser) => {
        return (
            <Routing realUser={realUser} />
        );
    };

    const trimObjValues = (obj) => {
        return Object.keys(obj).reduce((acc, curr) => {
            acc[curr] = obj[curr].trim()
            return acc;
        }, {});
    }

    useEffect(() => {
        if (location.state && location.state.user.length > 3) {
            setIsLoggedIn({ username: location.state.user, password: location.state.password, showPassword: false });
        } else {
        }
    }, [location.state]);
    //Gets the UserName and Password from the backend to match
    const onSubmit = async (data, e) => {
        data = { user: isLoggedIn.username, password: isLoggedIn.password, apiKey }
        setLoading(true);
        const newData = trimObjValues(data)
        let john = [];
        await getLogin(newData, john)
        if (newData.user === "") {
            Swal.fire({
                icon: 'error',
                title: 'Server Could be down!',
                text: 'Please check Username and Password',
            })
            setLoading(false)
            e.target.reset();
        }
        if (john[0] === undefined || null) {
            Swal.fire({
                icon: 'error',
                title: 'Server Could be down!',
                text: 'Please check Username and Password',
            })
            john = [{}];
            setLoading(false);
            e.target.reset();
        }
        if (john[0].LogggedIn === "Yes") {
            let keyToken = john[0].accessToken
            let theKey = [];
            await createKey(data);

            const newData = { user: data.user, password: data.password, newToken: keyToken }
            await getKey(newData, theKey, keyToken);
            let newKeyValue = theKey[0].theKey[0].key;
            let lastKey = [];
            for (const element in newKeyValue) {
                let newKey = element.key;
                lastKey.push(newKey);
            }
            let url = document.location.href;
            let urlparts = url.slice(0, -5);
            window.history.pushState('', document.title, urlparts);

            setTimeout(function () {
                const realUser = data.user
                const user = data.user
                nav(`/Home`, { state: { realUser, user } })
                handleLogin(data.user)
                john = [{}];
                data = [];
                e.target.reset();
                setLoading(false);
            }, 3);
        }
    }
    //    <CloseOutlinedIcon style={{ float: "right", marginRight: "5px", marginTop: "5px", marginBottom:"5px" }} onClick={closeLoginBox} />
    return (
        <>
            <HomePageBar></HomePageBar>
            <Card sx={{ maxWidth: "350px", justifyContent: "center", marginLeft: "auto", marginRight: "auto",marginBottom:"350px", marginTop:"3rem"}}>
            <CardMedia className='imgLogin'
                  component="Img3"
                  alt="Login Pic"
                  style={{ objectFit: "cover", height: '15rem', marginBottom: "1.8rem", marginTop: "1.8rem" }}
                />
                <CardContent>
                <FormControl>
                    <form onSubmit={handleSubmit(onSubmit)}>  
                            <InputLabel htmlFor="outlined-adornment-username">Username</InputLabel>
                            <OutlinedInput
                                required
                                style={{maxWidth:"auto"}}
                                fullWidth
                                inputProps={{ maxLength: 25, minLength: 3 }}
                                {...register("user")}
                                id="outlined-adornment-username"
                                autoComplete="username"
                                type={isLoggedIn.username ? 'text' : 'username'}
                                value={isLoggedIn.username}
                                onChange={handleChange('username')}
                                endAdornment={<InputAdornment position="end">
                                    <PersonIcon
                                    >
                                        {isLoggedIn.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </PersonIcon>
                                </InputAdornment>}
                                label="Password" />
                            <FormControl style={{ marginTop: '1rem',minWidth:"318px" }}>
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    required
                                    style={{maxWidth:"auto"}}
                                    fullWidth
                                    aria-autocomplete='both'
                                    autoComplete="current-password"
                                    inputProps={{ maxLength: 25, minLength: 3 }}
                                    {...register("password")}
                                    id="outlined-adornment-password"
                                    type={isLoggedIn.showPassword ? 'text' : 'password'}
                                    value={isLoggedIn.password}
                                    onChange={handleChange('password')}
                                    endAdornment={<InputAdornment position="end">
                                        <IconButton
                                            style={{marginRight:"-0.5rem"}}
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {isLoggedIn.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>}
                                    label="Password" />
                                <LoadingButton
                                    style={{ backgroundColor: "#e4f3e0",color: "#444444", marginTop: "1rem" }}
                                    type="submit"
                                    checked={loading}
                                    onChange={() => setLoading(!loading)}
                                    name="loading"
                                    color="primary"
                                    endIcon={<SendIcon style={{marginLeft:"1rem"}} />}
                                    loading={loading}
                                    loadingPosition="end"
                                    variant="contained"
                                >
                                    Login
                                </LoadingButton>
                                <LoadingButton
                                    style={{ backgroundColor: "#e4f3e0", color: "#444444", marginTop: "1rem" }}
                                    variant="contained"
                                    color="primary"
                                    onClick={gotoCreate}
                                    name="loading"
                                    endIcon={<AccountCircleIcon />}
                                >
                                    Sign up
                                </LoadingButton>
                                <Button variant='text' onClick={gotoPasswordRecovery}>
                                    <Typography style={{ textDecoration: 'underline', marginTop: "1rem" }}>Forgot Password</Typography>
                                </Button>
                                </FormControl>
                    </form>
                    </FormControl>
                </CardContent>
            </Card>
            <Footer></Footer>
        </>
    )
}
export default LoginPage