import React from "react";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';

export function TweetButton({ text, user }) {
    const tweetText = encodeURIComponent(text);
    const tweetUrl = encodeURIComponent(`https://localproject.app/userprojects/${user}`);
    const tweetHref = `https://twitter.com/intent/tweet?text=${tweetText}&url=${tweetUrl}`;
    return (
      <a href={tweetHref} target="_blank" rel="noopener noreferrer">
        <TwitterIcon style={{ color: "whitesmoke", marginTop: "15px", WebkitTextStroke: "0.1px black", marginRight: "10px" }}></TwitterIcon>
      </a>
    );
  }

  export function WhatsAppButton({ user }) {
    const messageText = encodeURIComponent(`https://localproject.app/userprojects/${user}`);
    const messageHref = `https://api.whatsapp.com/send?text=${messageText}`;
    return (
      <a href={messageHref} target="_blank" rel="noopener noreferrer">
        <WhatsAppIcon style={{ color: "whitesmoke", marginTop: "15px", WebkitTextStroke: "0.1px black" }}></WhatsAppIcon>
      </a>
    );
  }