import { addImgToProject, getUserImages, updateImage } from '../services/InboxService'
import LoginBarTop from './LoginBarTop';
import Images from './Images';
import React, { useState, useEffect } from 'react';
import ShowImages from "./ShowImages";
import { getInbox } from '../services/InboxService';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation } from 'react-router-dom';
import HomePageBar from './HomePageBar';
import Footer from './Footer';
import Card from '@mui/material/Card';
import Typography from '@material-ui/core/Typography';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
const Swal = require('sweetalert2');

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(project, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(project) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const UploadImages = ({tweetUser,image}) => {
  
  const location = useLocation();
  const state = location.state || "";
  const realUser = state.realUser || "";

  const user = state.user;
  const theme = useTheme();
  const [personName, setPersonName] = useState('');
  const [projects, setProjects] = useState([""]);
  const [userImages, setUserImages] = useState([]);
  const [imagess, setImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [newImage, setNewImage] = useState([]);
  const [imgDelete, setImgDelete] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const maxSizeInBytes = 5 * 1024 * 1024;

  const newUser = { user };

  useEffect(() => {
    getInbox(newUser)
      .then(inboxes => {
        const projectFiles = inboxes[0].projects;
        const userProject = Object.keys(projectFiles).map(key => {
          const { name, _id: id } = projectFiles[key];
          return { name, id };
        });
        setProjects(userProject);
      })
      .catch(error => {
        // Handle any errors here
        console.error("Error fetching project data:", error);
      });
    return () => { };
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    window.onbeforeunload = function (e) {
      return false;
    };

    fetchImages()

    async function fetchImages() {
      try {
        const response = await fetch('https://localprojectapi-api.vercel.app/file');
        const data = await response.json();
        const newData = [...data]
        setImages(newData);
      } catch (error) {
      }
    }

    const data = { user: user };
    getUserImages(data).then(response => {
      const newData = [...response]
      setUserImages(newData);
    });

  }, [user, newImage, imgDelete]);

  const uploadLinkImg = (success) => {
    const imgLink = success.imgUrl;
    const title = success.title;
    const description = success.description;
    const projectId = success.projectId;
    const data = { user, imgLink, title, description, projectId };
    //Updates inbox with linked images
    updateImage(data).then(success => {
      Swal.fire(
        'Image uploaded',
        'Congrats!',
        'success'
      )
      handleToggle();
    });

    getUserImages(data).then(response => {
      const newData = [...response]
      setUserImages(newData);
      setNewImage(newData);
    });

    let name = personName;
    let cabage = { user: data.user, imgLink, name }
    addImgToProject(cabage).then(success => {
      console.log(cabage);
      console.log(success);
    });
  }

  const resizeImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas")
      const ctx = canvas.getContext("2d")
      const img = new Image();
      img.onload = () => {
        let width = img.width
        let height = img.height
        if (width > maxWidth) {
          height *= maxWidth / width
          width = maxWidth;
        }
        if (height > maxHeight) {
          width *= maxHeight / height
          height = maxHeight;
        }
        canvas.width = width
        canvas.height = height
        ctx.drawImage(img, 0, 0, width, height)
        canvas.toBlob((blob) => {
          const resizedFile = new File([blob], file.name, {
            type: file.type,
            lastModified: file.lastModified,
          });
          resolve(resizedFile);
        }, file.type, 0.8)
      };
      img.src = URL.createObjectURL(file);
    });
  };

  const onImageChange = async (e) => {
    const title = document.getElementById("title").value;
    const description = document.getElementById("description").value;
    const files = document.getElementById("file");
    if (files.files[0].size > 4194304) {
      Swal.fire("5mb Limit");
    } else {
      const formData = new FormData();
      for (let i = 0; i < files.files.length; i++) {
        const resizedFile = await resizeImage(files.files[0]);
        formData.append("file", resizedFile)
        formData.append("filename", resizedFile.name)
        formData.append("type", resizedFile.type)
        formData.append("size", resizedFile.size)
        formData.append("lastModified", resizedFile.lastModified)
        formData.append("title", title)
        formData.append("description", description)
        formData.append("projectId", personName)
        formData.append("metadata", "metadata")
        upload(formData)
      }
    }
  };

  const upload = async (formData) => {
    await fetch('https://localprojectapi-api.vercel.app/file/upload', {
      // Your POST endpoint
      method: 'POST',
      header: {},
      body: formData// This is your file object
    }).then(
      response => response.json()
        .then(
          success => uploadLinkImg(success))// Handle the success response object
    ).catch(
      error => console.log(error) // Handle the error response object
    );
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setPersonName(value);
  };

  const handleChanges = () => {
    setChecked((prev) => !prev);
  };

  return (
    <><>
      {realUser !== undefined && realUser !== "" ? (
        <>
          <LoginBarTop realUser={realUser} />
        </>
      ) : <HomePageBar />}
      <div onBeforeInput={handleChanges} className="box">
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <>
            <Grid
              container spacing={2} columns={16} mt={1}
            >
              <Grid
                item xs={4} ms={3} sx={{ minWidth: "500px" }}
              >
                <Card >
                  <CardMedia
                    component="img"
                    sx={{
                      width: '100%',
                      objectFit: 'cover',
                    }} />
                  <ShowImages style={{ marginBottom: "10px" }} imagess={imagess} userImages={userImages}></ShowImages>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {user}s Project Photos
                    </Typography>
                    {realUser !== undefined && realUser === user &&     <FormControl sx={{ m: 2, marginBottom: "5px", zIndex: 1000 }}>
                      <label htmlFor='metadata' id='metadata' type="metadata" onChange={onImageChange} hidden>Your name</label>
                        <InputLabel>Choose Project</InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput label="Choose Project" />}
                        MenuProps={MenuProps}>
                        {projects?.map((project, index) => (
                          <MenuItem
                            key={project._id + 1} // Provide a unique key based on the 'name' property
                            id={project.name}
                            value={project.name}
                            style={getStyles(project.name, personName, theme)}
                          >
                            {project.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <TextField style={{ marginTop: "7px" }} label="Title" variant="outlined" id="title" placeholder="Title" />
                      <TextField style={{ marginTop: "7px" }} label="Description" variant="outlined" id="description" placeholder="Enter Description" />
                      <div className="button is-info buttonStyle" style={{ marginTop: "7px" }}>
                        <input className="file-input" onClick={handleToggle} placeholder='file-upload' alt="file uploader" htmlFor='file' id='file' type="file" multiple accept='image/*' encType='multipart/form-data' onChange={onImageChange} />
                        Choose a file
                      </div>
                    </FormControl>}
                  </CardContent>
                  <CardActions></CardActions>
                </Card>
              </Grid>
              <Grid item xs={8} ms={3} sx={{ minWidth: "500px" }}>
                <Images user={user} realUser={realUser} setUserImages={setUserImages} imagess={imagess} userImages={userImages} setImgDelete={setImgDelete}></Images>
              </Grid>
            </Grid>
        </>
      </div>
    </><Footer /></>
  )
}
export default UploadImages;