import React, { useState, useEffect, useRef } from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia';
import EditIcon from '@mui/icons-material/Edit'
import Footer from './Footer'
import Grid from '@mui/material/Grid'
import LoginBarTop from './LoginBarTop'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { useLocation, useNavigate } from 'react-router-dom'
import { updateUserImage, getInbox } from '../services/InboxService'
import { isOnline } from '../services/OnlineService'
import { TextField, Typography } from '@mui/material'
import { updateOnlineImage } from '../services/OnlineService'

const UserInfo = () => {
  const nav = useNavigate();
  const newLocation = useLocation()
  const state = newLocation.state;
  const realUser = state.realUser;
  const user = state.user;
  if (realUser === null) {
    nav('/');
  }

  const [userImg, setUserImg] = useState("")
  const [userImgBg, setUserImgBg] = useState("")
  const [ImgId, setImgId] = useState([])
  const [name, setName] = useState([])
  const [comments, setComments] = useState([])
  const [skills, setSkills] = useState([])
  const [btc, setBtc] = useState([])
  const [location, setLocation] = useState([])
  const [complete, setComplete] = useState(false)
  const [isHover, setIsHover] = useState(false);
  const [isHoverTwo, setIsHoverTwo] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [imgIdClick, setImgIdClick] = useState(false);
  const boxStyleAvatar = {
    opacity: isHoverTwo ? '0.20' : '1',
    marginTop:"3rem",
    cursor: 'pointer',
  };
  const boxStyleBackground = {
    opacity: isHover ? '0.20' : '1',
    backgroundColor: isHover ? '' : 'lightblue',
    cursor: 'pointer',
  };
  useEffect(() => {
    const balls = { user }
    getInbox(balls).then(inboxes => {
      setUserImg(inboxes[0].userImg[0].imgLink)
      setUserImgBg(inboxes[0].userImg[0].imgBg)
      setImgId(inboxes[0].userImg[0]._id)
      setName(inboxes[0].userImg[0].name)
      setComments(inboxes[0].userImg[0].comments)
      setSkills(inboxes[0].userImg[0].skills)
      setBtc(inboxes[0].userImg[0].btc)
      setLocation(inboxes[0].userImg[0].location)
    });
  }, [complete, user])
  const resizeImage = (file, maxWidth, maxHeight) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas")
      const ctx = canvas.getContext("2d")
      const img = new Image();
      img.onload = () => {
        let width = img.width
        let height = img.height
        if (width > maxWidth) {
          height *= maxWidth / width
          width = maxWidth;
        }
        if (height > maxHeight) {
          width *= maxHeight / height
          height = maxHeight;
        }
        canvas.width = width
        canvas.height = height
        ctx.drawImage(img, 0, 0, width, height)
        canvas.toBlob((blob) => {
          const resizedFile = new File([blob], file.name, {
            type: file.type,
            lastModified: file.lastModified,
          });
          resolve(resizedFile);
        }, file.type, 0.8)
      };
      img.src = URL.createObjectURL(file);
    });
  };
  const onImageChange = async (e) => {
    const files = document.getElementById("file");
    if (files.files[0].size > 4194304) {
      Swal.fire("5mb Limit");
    } else {
      const formData = new FormData();
      for (let i = 0; i < files.files.length; i++) {
        const resizedFile = await resizeImage(files.files[0], 1000, 1000);
        formData.append("file", resizedFile)
        formData.append("filename", resizedFile.name)
        formData.append("type", resizedFile.type)
        formData.append("size", resizedFile.size)
        formData.append("lastModified", resizedFile.lastModified)
        formData.append("metadata", "metadata")
        upload(formData)
      }
    }
  };

  const upload = async (formData) => {
    await fetch('https://localprojectapi-api.vercel.app/file/upload', {
      // Your POST endpoint
      method: 'POST',
      header: {},
      body: formData// This is your file object
    }).then(
      response => response.json()
        .then(
          success => uploadLinkImg(success))// Handle the success response object
    ).catch(
      error => console.log(error) // Handle the error response object
    );
  };

  const uploadLinkImg = (success) => {
    let imgBg = userImgBg;
    let imgLink = userImg;
    if(imgIdClick) {
       imgBg = success.imgUrl
    } else {
      imgLink = success.imgUrl;
    }
    let data = { user: user, _id: ImgId, imgBg, imgLink, name: name, comments: comments, skills, location };
    isOnline(data);
    updateUserImage(data).then(success => {
      Swal.fire(
        'Image uploaded',
        'Congrats!',
        'success'
      )
      setUserImg(data.imgLink);
      setUserImgBg(data.imgBg);
      setImgId(data._id);
      updateOnlineImage(data).then(success => {
      });
    });
    setComplete(true)
  }
  const handleMouseEnterAvatar = () => { 
    setIsHoverTwo(true);  
document.getElementById('userImage').style.display = 'block';
  };
  const handleMouseLeaveAvatar = () => {
    setIsHoverTwo(false);
    document.getElementById('userImage').style.display = 'none';
  };
  const handleMouseEnterBackground = () => {
    setIsHover(true);
    document.getElementById('userBackground').style.display = 'block';
  };
  const handleMouseLeaveBackground = () => {
    setIsHover(false);
    document.getElementById('userBackground').style.display = 'none';
  };
  const onButtonClick = (e) => {
   inputFile.current.click();
  };
  const inputFile = useRef(null)
  const handleInputChange = (e) => {
    const name = e.target.value;
    let data = { user: user, imgLink: userImg, name: name, imgBg: userImgBg,  comments: comments, skills, location, btc };
    updateUserImage(data).then(success => {
      setName(data.name);
    });
  }
  const handleCommentsChange = (e) => {
    const comments = e.target.value;
    let data = { user: user, imgLink: userImg, name: name, imgBg: userImgBg,  comments: comments, skills, location, btc };
    //Updates inbox with linked images
    updateUserImage(data).then(success => {
      setComments(data.comments);
    });
  }
  const handleSkillsChange = (e) => {
    const skills = e.target.value;
    let data = { user: user, imgLink: userImg, name: name,imgBg: userImgBg,  comments: comments, skills, location, btc };
    //Updates inbox with linked images
    updateUserImage(data).then(success => {
      setSkills(data.skills);
    });
  }
  const handleLocationChange = (e) => {
    const location = e.target.value;
    let data = { user: user, imgLink: userImg, name: name,imgBg: userImgBg,  comments: comments, skills, location, btc };
    //Updates inbox with linked images
    updateUserImage(data).then(success => {
      setLocation(data.location);
    });
  }
  const handleBtcChange = (e) => {
    const newBtc = e.target.value;
    let data = { user: user, imgLink: userImg, imgBg: userImgBg, name: name, comments, skills, location, btc: newBtc };
    //Updates inbox with linked images
    updateUserImage(data).then(success => {
      setBtc(data.btc);
    });
  }
  return (
    <>
      <LoginBarTop realUser={realUser} ></LoginBarTop>
      <Grid
        id="photoBlog"
        style={{ justifyContent: 'center' }}
        container
        spacing={1}
      >
        <Grid
          marginTop={4}
          marginLeft={1}
          marginRight={1}
          item
          xs={12}
          md={4}
        >
          <Card>
            <CardContent>
              <div style={{ position: 'relative' }}>
                <CardMedia
                  image={userImgBg}
                  style={boxStyleBackground}
                  key="CardMedia"
                  onClick={() => {
                    onButtonClick(); // Execute your existing click handler
                    setImgIdClick(true); // Set ImgIdClick to true
                  }}
                 
                  onMouseEnter={handleMouseEnterBackground}
                  onMouseLeave={handleMouseLeaveBackground}
                  id={ImgId}
                  component="Img"
                  alt=""
                  sx={{ zIndex:"-344",objectFit: "cover", height: '10rem',opacity: isHover ? '0.20' : '1',  cursor: 'pointer',}}
                />
                  <input hidden ref={inputFile} htmlFor='file' id='file' type="file" multiple accept='image/*' encType='multipart/form-data' onChange={onImageChange} />
                <Avatar
                  key="Avatar"
                  style={boxStyleAvatar}
                  onMouseEnter={handleMouseEnterAvatar}
                  onMouseLeave={handleMouseLeaveAvatar}
                  onClick={() => {
                    onButtonClick(); // Execute your existing click handler
                    setImgIdClick(false); // Set ImgIdClick to true
                  }}
                  id={ImgId}
                  alt="Remy Sharp"
                  src={userImg}
                  sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 140, height: 140, border: "0.1rem solid white" }}
                />    
               <Typography variant="h6" color="textSecondary" >{user}</Typography>
              </div>
              <Typography sx={{float:"right"}}  mt={-4} variant="body1" color="textSecondary" id="userImage">
              {isHoverTwo !== false && (
                <>
                  <EditIcon></EditIcon>Edit Avatar
                </>
              )}
            </Typography>
            <Typography sx={{float:"right"}} mt={-4} variant="body1" color="textSecondary" id="userBackground">
              {isHover !== false && (
                <>
                  <EditIcon></EditIcon>Edit Background
                </>
              )}
            </Typography>
              <div style={{ marginTop: "1.5rem" }}>
                <Typography  variant="body1" color="textSecondary" style={{ marginTop: "5px", fontWeight: "bold" }} >Location</Typography>
                {isEditing ?
                  <form>
                    <TextField  type='text' onChange={handleLocationChange} placeholder="Location" defaultValue={location} />
                  </form>
                  : <Typography onClick={() => setIsEditing(true)}>{location}</Typography>}
                <Typography variant="body1" color="textSecondary" style={{ marginTop: "5px", fontWeight: "bold" }}>Real Name</Typography>
                {isEditing ?
                  <form>
                    <TextField type='text' min="1" max="5" onChange={handleInputChange} placeholder="edit name" defaultValue={name} />
                  </form>
                  : <Typography variant="body1" color="textSecondary" onClick={() => setIsEditing(true)}>{name}</Typography>}
                <Typography variant="body1" color="textSecondary" style={{ marginTop: "5px", fontWeight: "bold" }} >Status</Typography>
                {isEditing ?
                  <form>
                    <TextField  type='text' onChange={handleCommentsChange} placeholder="update Status" defaultValue={comments} />
                  </form>
                  : <Typography variant="body1" color="textSecondary" onClick={() => setIsEditing(true)}>{comments}</Typography>}
                <Typography variant="body1" color="textSecondary" style={{ marginTop: "5px", fontWeight: "bold" }} >Skills</Typography>
                {isEditing ?
                  <form>
                    <TextField  type='text' onChange={handleSkillsChange} placeholder="update Skills" defaultValue={skills} />
                  </form>
                  : <Typography variant="body1" color="textSecondary" onClick={() => setIsEditing(true)}>{skills}</Typography>}
                <Typography variant="body1" color="textSecondary" style={{ marginTop: "5px", fontWeight: "bold" }} >BTC Address</Typography>
                {isEditing ?
                  <form>
                    <TextField type='text' onChange={handleBtcChange} placeholder="update btc address" defaultValue={btc} />
                  </form>
                  : <Typography variant="body1" color="textSecondary" onClick={() => setIsEditing(true)}>{btc}</Typography>}
                <Button variant="outlined" sx={{ marginTop: "1rem", marginBottom: "1rem", float: 'right', marginLeft: "1rem" }} onClick={() => setIsEditing(false)} >Save</Button>
                <Button variant="outlined" sx={{ marginTop: "1rem", marginBottom: "1rem", float: 'right' }} onClick={() => setIsEditing(true)} >Edit</Button>
              </div>
            </CardContent>
            <CardActions></CardActions>
          </Card>
        </Grid>
      </Grid>
      <Footer />
    </>
  )
}
export default UserInfo;