import React, { useMemo, useCallback, useRef } from "react"
import L from "leaflet"
import { Marker, Popup } from "react-leaflet"
import { Avatar, Button, Typography } from "@mui/material"
import { useNavigate } from 'react-router-dom'
import { deleteSharedProject } from "../services/SharedService"
import { useForm } from "react-hook-form";
import { sendProjectComment } from '../services/InboxService';
import Swal, {} from 'sweetalert2/dist/sweetalert2.js'
import { TextField } from "@material-ui/core"

export const MapMarkers = React.memo(({ mapPoints, realUser, setChecked }) => {
  const { register, handleSubmit } = useForm();
  const nav = useNavigate();
  const goToUserProfile = useCallback((e) => {
    const userId = e.currentTarget.id;
    nav(`/UserProjects/${userId}`, { state: { user: e.currentTarget.id, realUser } });
    setChecked(false)
  }, [setChecked, nav, realUser])
  const removeShared = (e) => {
    const _id = e.currentTarget.id
    const data = { _id }
    deleteSharedProject(data)
    popupContentRef.current.close()
  }
  const popupContentRef = useRef();
  const markers = useMemo(() => {
    const onSubmit = (data) => {
      const MAX_MESSAGE_LENGTH = 60;
      if (data.createdBy === "Guest" && data.message.length > MAX_MESSAGE_LENGTH) {
        Swal.fire("Guest users are limited to messages of up to 60 characters.");
      } else {
        const time = new Date().toLocaleTimeString();
        const day = new Date().toLocaleDateString('en-UK');
        const newData = { user: data.createdBy, theMessage: data.message, name: data.project, time: day + "@" + time, imgLink: "" };
        sendProjectComment(newData).then(response => {
          Swal.fire(
            'Message Sent',
            'Congrats!',
            'success'
          )
          popupContentRef.current.close()
        });
      }
    }
    
    const customIcons = mapPoints.map((x) => {
      return new L.Icon({
        iconUrl: x[4],
        iconSize: [32, 32], // Set the size of your custom icon
        iconAnchor: [16, 32], // Adjust the anchor point if needed
      });
    });

    return mapPoints.map((x, index) => (
      <>
        <Marker icon={customIcons[index % customIcons.length]} key={index} position={{ lat: x[0], lng: x[1] }}>
          <Popup ref={popupContentRef}>
            <Typography className="icon" id={x[3]} style={{ cursor: "pointer" }} title={x[3]} onClick={goToUserProfile}>
              <Avatar src={x[4]} id={x[3]} variant="contained"></Avatar> 
            </Typography>
            <Typography id={x[3]} onClick={goToUserProfile} style={{ marginTop: "4px", cursor: "pointer" }}>
              {x[2]} Project by {x[3]} 
            </Typography>
            <form style={{ width: "14rem" }} onSubmit={handleSubmit(onSubmit)}>
              <Typography {...register("createdBy")} type="text" className="form-control" name="createdBy" id="createdBy" value="Guest" hidden />
              <Typography {...register("project")} type="text" className="form-control" name="project" id="project" value={x[2]} hidden />
              <Typography {...register("createDate")} type="text" className="form-control" name="createDate" id="createDate" value={Date.now()} hidden />
              <TextField  style={{ marginTop: "5px" }} placeholder="Up to 60 characters as Guest" minRows={2} {...register("message")} type="text" name="message" required label="Message" />
              <Button style={{ marginTop: "10px", marginBottom: "10px" }} type="submit" >
                Send Message
              </Button>
              {realUser === x[3] &&<Button id={x[5]} onClick={removeShared}>Remove</Button>}
            </form>
          </Popup>
        </Marker></>
    ));
  }, [mapPoints, goToUserProfile, handleSubmit, register, realUser]);
  return markers
});