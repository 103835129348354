import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import LoginBarTop from './LoginBarTop'
import HomePageBar from './HomePageBar'
import {getDayWithSuffix} from './CalanderLogic'
import { Grid, Button, Typography } from '@mui/material'
import { updateCalandar, getCalandar } from '../services/InboxService'
import { useLocation, useNavigate } from 'react-router-dom'
import { Slider } from '@material-ui/core'


const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

const Calendar = () => {
  const nav = useNavigate()
  const location = useLocation()
  const state = location.state
  const user = state.realUser;
  const realUser = state.realUser;

  if (realUser === null) {
    nav('/');
  }
  // Get the current date and initialize state
  const now = new Date()
  const [currentMonth, setCurrentMonth] = useState(now.getMonth() + 1)
  const [currentYear, setCurrentYear] = useState(now.getFullYear())
  const [monthNamen, setMonthNamen] = useState(now.toLocaleString('default', { month: 'long' }))
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [date, setDate] = useState(new Date())
  const [calandar, setCalandar] = useState([])
  const [times, setTimes] = useState(new Date())

  useEffect(() => {
    const data = { user: realUser }
    getCalandar(data).then(inboxes => {
      const calandarInfo = inboxes[0].calander
      setCurrentMonth(currentMonth)
      setCalandar(calandarInfo)
    })
  }, [isDialogOpen])

  

  const handleDialogOpen = (day) => {
    setIsDialogOpen(true);
    setIsDialogOpen(true);
    setDate(new Date(currentYear, currentMonth - 1, day + 1))
  };  

  const handleChangeTime = (event, newValue) => {
    const hours = Math.floor(newValue);
    const minutes = Math.round((newValue - hours) * 60);
    const now = new Date();
    now.setHours(hours, minutes);
    setTimes(now);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false)
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value)
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value)
  };

  const handleDateChange = (event) => {
    setDate(new Date(event.target.value))
  };

  const handleSave = () => {
    
    const prevDay = date
    prevDay.setDate(prevDay.getDate() - 1)
    setDate(prevDay)
    const data = { user: realUser, title, description, date, time: `${times.getHours()}:${times.getMinutes()}` }
    updateCalandar(data)
    // Close the dialog
    setIsDialogOpen(false)
  };

  // Helper function to get the number of days in a month
  const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate()
  };

  // Helper function to generate an array of day numbers for a given month
  const getMonthDays = (month, year) => {
    const daysInMonth = getDaysInMonth(month, year)
    return Array.from({ length: daysInMonth }, (_, i) => i + 1)
  };

  // Helper function to handle clicking the next button
  const handleNextMonth = () => {
    if (currentMonth === 12) {
      setCurrentYear(currentYear + 1)
      setCurrentMonth(0);
      let writtenMonth = monthNames[currentMonth]
      setMonthNamen(writtenMonth)
    } else {
      let writtenMonth = monthNames[currentMonth]
      setCurrentMonth(currentMonth + 1)
      setMonthNamen(writtenMonth)
    }
  };

  const handlePrevMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(12);
      setCurrentYear(currentYear - 1)
    } else {
      let writtenMonth = monthNames[currentMonth - 2]
      setCurrentMonth(currentMonth - 1)
      setMonthNamen(writtenMonth)
    }
  }

  const handleTimeChange = (event) => {
    const selectedTime = event.target.value;
    const [hours, minutes] = selectedTime.split(":");
    const newTime = new Date();
    newTime.setHours(parseInt(hours));
    newTime.setMinutes(parseInt(minutes));
    setTimes(newTime);
  };

  // Generate the array of day numbers for the current month
  const monthDays = getMonthDays(currentMonth, currentYear)
  let daytime
  return (
    <>
      {realUser !== undefined && realUser !== "" ? (
        <>
          <LoginBarTop realUser={realUser} />
        </>
      ) : <HomePageBar />}
      <Grid style={{ justifyContent: 'center' }}
        container
        spacing={1}
        mt={4}>
        <Grid item xs={4} sx={{ minWidth: "400px" }}
        >
          <Typography style={{ marginLeft: "120px" }}>
          </Typography>
          <Typography  style={{ marginTop: "10px" }}>  {monthNamen} {currentYear}</Typography>
          
          <Button className="buttonStyle" style={{ marginTop: "10px", marginBottom: "20px" }} variant="contained" onClick={handlePrevMonth}>
            Prev
          </Button>
            <Button className="buttonStyle" style={{ marginTop: "10px", marginLeft: "10px", marginBottom: "20px" }} variant="contained" onClick={handleNextMonth}>
              Next
            </Button>
          {monthDays.map((day,index) => (
            <Card key={index}  style={{ cursor: 'pointer', marginTop: "10px", marginRight: "20px", color: "grey" }} onClick={() => handleDialogOpen(day)}>
              <CardContent>
              <Typography style={{ float: "right" }} gutterBottom variant="body2" component="div">{getDayWithSuffix(day) + " " + monthNamen}</Typography>
              <div hidden> {daytime = day + " " + monthNamen + " " + currentYear} </div>
              {calandar.map((newData) => (
                new Date(newData.date).toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }) === daytime && (
                  <>
                    <div style={{ display: "grid", gridTemplateColumns: "auto 1fr", gap: "20px" }}>
                      <Typography variant="body2">Title: {newData.title}</Typography>
                      <Typography variant="body2">Time: {newData.time}</Typography>
                      <Typography variant="body2">Description: {newData.description}</Typography>
                    </div>
                  </>
                )
              ))}
              </CardContent>
            </Card>
          ))}
          <Dialog open={isDialogOpen} onClose={handleDialogClose}>
            <DialogTitle>Add Event</DialogTitle>
            <DialogContent>
              <TextField style={{ marginTop: "10px" }} label="Title" value={title} onChange={handleTitleChange} fullWidth />
              <TextField style={{ marginTop: "10px" }} label="Description" value={description} onChange={handleDescriptionChange} fullWidth />
              <TextField style={{ marginTop: "10px" }} label="Date" type="date" value={date.toISOString().substr(0, 10)} onChange={handleDateChange} fullWidth />
              <TextField
                style={{ marginTop: "10px" }}
                label="Time"
                type="text"
                value={`${times.getHours()}:${times.getMinutes()}`}
                fullWidth
              />
              <Slider
                value={times.getHours() + times.getMinutes() / 60}
                onChange={handleChangeTime}
                aria-labelledby="continuous-slider"
                step={1 / 60}
                min={0}
                max={24}
              />
            </DialogContent>
            <DialogActions>
              <Button className="buttonStyle" variant="contained" onClick={handleDialogClose}>Cancel</Button>
              <Button className="buttonStyle" variant="contained" onClick={handleSave} disabled={!title || !description || !date}>Save</Button>
            </DialogActions>
          </Dialog>
          <Grid item xs={12}>
            <Button className="buttonStyle" style={{ float: "left", marginTop: "10px", marginLeft: "60px", marginBottom: "20px" }} variant="contained" onClick={handlePrevMonth}>
              Prev
            </Button>
            <Button className="buttonStyle" style={{ float: "right", marginTop: "10px", marginRight: "60px" }} variant="contained" onClick={handleNextMonth}>
              Next
            </Button>
          </Grid>
        </Grid></Grid></>
  )
}
export default Calendar