import React, { useState, useEffect } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import { Typography } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { shareProject } from "../services/SharedService"

const LocalChats = () => {
  const [data, setData] = useState([])
  const [randomCards, setRandomCards] = useState([])
  const navigate = useNavigate()
  useEffect(() => {
    shareProject().then((response) => {
      const newMarkers = response[0].projects.map((marker) => {
        const [lat, lng, projectName, createdBy, imgUser, _id] = marker.location.split(',').map((str, index) => (index === 0 || index === 1 ? parseFloat(str) : str))
        return [lat, lng, projectName, createdBy, imgUser, _id]
      });
      setData(newMarkers);
    });
  }, []);

  useEffect(() => {
    const getRandomCards = (array, num) => {
      const shuffledArray = array.slice(); // Create a copy of the array to avoid mutating the original
      let currentIndex = shuffledArray.length
      let temporaryValue, randomIndex
      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1;
        temporaryValue = shuffledArray[currentIndex]
        shuffledArray[currentIndex] = shuffledArray[randomIndex]
        shuffledArray[randomIndex] = temporaryValue
      }
      return shuffledArray.slice(0, num)
    }
    const cards = getRandomCards(data, 5)
    setRandomCards(cards);
  }, [data])

  const handleUserProfile = (userId) => {
    navigate(`/UserProjects/${userId}`);
  }
  return (
    <section style={{display: "flex", justifyContent: "center", flexWrap: "wrap", marginTop: "1rem" }}>
      {randomCards.map((location) => (
        <Card key={location[5]} style={{backgroundColor:"lightblue",objectFit:"cover", background:`url(${location[5]})`, marginLeft: "2rem", width: "300px", marginBottom: "10px" }}>
          <CardHeader style={{ fontSize: "18px", color: 'whitesmoke', textShadow: '1px 1px 1px black' }}
            avatar={
              <Avatar
                decoding="async"
                imageRendering="auto"
                sx={{ width: 70, height: 70 }}
                src={location[4]}
                aria-label="Img"
              />
            }
            title={location[3]}
          />
          <CardContent>
          <Typography style={{ fontSize: "16px", color: 'whitesmoke', textShadow: '1px 1px 1px black' }}>
              My Project {location[2]}
            </Typography>
          </CardContent>
          <CardActions style={{ float: "right" }}>
            <Button variant="text" style={{color:"whitesmoke"}} onClick={() => handleUserProfile(location[3])}>
              Visit {location[3]}
            </Button>
          </CardActions>
        </Card>
      ))}
    </section>
  )
}
export default LocalChats;