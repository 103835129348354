export async function getInbox(data) {
    try {
      const response = await fetch(`https://localprojectapi-api.vercel.app/api/getInbox`, {
        method: 'POST',      
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
      });
      const jsonData = await response.json();
      if (jsonData.length === 0) {
       // throw new Error('Sorry no data found please contact Local Project');
      }
      return jsonData;
    } catch (error) {
    }
  }

  export async function updateCalandar(data) {
    try {
      const response = await fetch('https://localprojectapi-api.vercel.app/api/updateCalandar', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ inbox: data })
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error('Error in updateCalendar:', error);
      throw error; // Re-throw the error for the caller to handle if needed
    }
  }
  
export async function getCalandar(data) {
    try {
      const response = await fetch('https://localprojectapi-api.vercel.app/api/getCalandar', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ inbox: data })
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error('Error in :', error);
      throw error; // Re-throw the error for the caller to handle if needed
    }
  }
  
export async function getSkills(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/getSkills`, {
        method: 'POST',  
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function getRewards(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/getRewards`, {
        method: 'POST',  
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function sendProjectComment(data) {
    try {
      const response = await fetch('https://localprojectapi-api.vercel.app/api/sendProjectComment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ inbox: data })
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error('Error in sendProjectComment:', error);
      throw error; // Re-throw the error for the caller to handle if needed
    }
  }

export async function sendProjectCommentReply(data) {
    try {
      const response = await fetch('https://localprojectapi-api.vercel.app/api/sendProjectCommentReply', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ inbox: data })
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error('Error in sendProjectCommentReply:', error);
      throw error; // Re-throw the error for the caller to handle if needed
    }
  }

export async function addDonations(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/addDonations`, {
        method: 'POST',
       
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function getFriends(data) {
    try {
        const response = await fetch(`https://localprojectapi-api.vercel.app/api/getFriends`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ inbox: data })
        });

        if (!response.ok) {
            // Handle the server error, e.g., log it or throw a custom error
            throw new Error(`Server returned ${response.status} ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        // Handle any other errors, e.g., network errors
        
        throw error; // Optionally rethrow the error to propagate it further
    }
}

export async function createInbox(data) {
    try {
      const response = await fetch('https://localprojectapi-api.vercel.app/api/createInbox', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ inbox: data })
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error('Error in createInbox:', error);
      throw error; // Re-throw the error for the caller to handle if needed
    }
  }

export async function deleteInbox(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/inboxes`, {
        method: 'PUT',   
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function updateInbox(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/inbox`, {
        method: 'PUT',    
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function getUserImages(data) {
    try {
      const response = await fetch('https://localprojectapi-api.vercel.app/api/getUserImages', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ inbox: data })
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      console.error('Error in getUserImages:', error);
      throw error; // Re-throw the error for the caller to handle if needed
    }
  }

export async function deleteImages(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/deleteImages`, {
        method: 'POST', 
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function deleteImgFromProject(data) {
  const response = await fetch(`https://localprojectapi-api.vercel.app/api/deleteImgFromProject`, {
      method: 'POST', 
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ inbox: data })
  })
  return await response.json();
}

export async function addFriends(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/addFriends`, {
        method: 'PUT', 
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function updateSkills(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/updateSkills`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function userReadMessage(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/userReadMessage`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function updateInboxReply(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/updateInboxReply`, {
        method: 'PUT',
        
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function replyInbox(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/replyInbox`, {
        method: 'PUT',
        
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function updateImage(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/image`, {
        method: 'PUT',
        
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function updateUserImage(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/updateUserImage`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function addProjectMembers(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/addProjectMembers`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function deleteProjectComment(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/deleteProjectComment`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function deleteProjectReply(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/deleteProjectReply`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function addExceptedFriend(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/addExceptedFriend`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function removeFriend(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/removeFriend`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function removeMember(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/removeMember`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function blockUser(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/blockUser`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function addToProjects(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/addToProjects`, {
        method: 'PUT',
        
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function addImgToProject(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/addImgToProject`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function createProject(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/createProject`, {
        method: 'PUT',     
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function editProject(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/editProject`, {
        method: 'PUT',    
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function removeProject(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/removeProject`, {
        method: 'PUT',      
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function uploadRewards(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/uploadRewards`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function editRewards(data) {
    const response = await fetch(`https://localprojectapi-api.vercel.app/api/editRewards`, {
        method: 'PUT',      
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inbox: data })
    })
    return await response.json();
}

export async function fetchSettings() {
    const response = await fetch('/api/settings');
    return await response.json();
}