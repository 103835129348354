import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function ShowImages({ userImages }) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const newImages = [];

    for (const key in userImages) {
      if (Object.hasOwnProperty.call(userImages, key)) {
        const userImagesData = userImages[key];
        if (userImagesData && userImagesData.images) {
          const imagesData = userImagesData.images;
          for (const imageKey in imagesData) {
            if (Object.hasOwnProperty.call(imagesData, imageKey)) {
              const label = imagesData[imageKey].title + '  ' + imagesData[imageKey].description;
              const imgPath = imagesData[imageKey].imgLink;
              newImages.push({ label, imgPath });
            }
          }
        }
      }
    }

    setImages(newImages);

    

  }, [userImages]);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
        <Typography>
          {images.length > 0 ? images[activeStep]?.label : ''}
        </Typography>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((image, index) => (
          <div key={index}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                imageRendering="optimizeSpeed"
                sx={{
                  height: 200,
                  display: 'block',
                  maxWidth: 200,
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={image.imgPath}
                alt={image.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
    </Box>
  );
}
export default ShowImages;