import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { shareProject } from "../services/SharedService";
import { MapMarkers } from "./MapMarkers";
export default function SharedProjects({ realUser, setChecked }) {
const [data, setData] = useState([]);
const [position] = useState([47.217324, 13.097555]);
const [showForm, setShowForm] = useState(false);
const [setClickLocation] = useState(null);
const [projectName, setProjectName] = useState("");
const [description, setDescription] = useState("");
  useEffect(() => {
    let newLocations = [];
    shareProject().then((response) => {
      let newMarkers = response[0].projects;
      for (const key in newMarkers) {
        const element = newMarkers[key].location;
        const _id = newMarkers[key]._id;
        let splitLocation = element.split(",");
        let lat = parseFloat(splitLocation);
        let lng = parseFloat(splitLocation[1]);
        let projectName = splitLocation[2];
        let createdBy = splitLocation[3];
        let imgUser = splitLocation[4];
        newLocations.push([lat, lng, projectName, createdBy, imgUser, _id]);
      }
      setData(newLocations);
    });
  }, []);
  const handleMapClick = (event) => {
    const { lat, lng } = event.latlng;
    setClickLocation([lat, lng]);
    setShowForm(true);
  };
  const handleProjectNameChange = (event) => {
    setProjectName(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setProjectName("");
    setDescription("");
    setShowForm(false);
    setClickLocation(null);
  };
  const CloseForm = () => {
    setShowForm(false);
    setClickLocation(null);
  };
  return (
    <MapContainer
      id="mapPane"
      style={{ zIndex: "22", height: "840px", margin: "0 auto" }}
      center={position}
      zoom={5}
      scrollWheelZoom={true}
      onClick={handleMapClick}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {showForm && (
        <div className="form-overlay">
          <div className="form-container">
            <h3>Add Project</h3>
            <form onSubmit={handleSubmit}>
              <label htmlFor="projectName">Project Name:</label>
              <input type="text" id="projectName" value={projectName} onChange={handleProjectNameChange} />
              <label htmlFor="description">Description:</label>
              <textarea id="description" value={description} onChange={handleDescriptionChange} />
              <button type="submit">Submit</button>
              <button type="button" onClick={CloseForm}>Close Form</button>
            </form>
          </div>
        </div>
      )}
      <MapMarkers mapPoints={data} setChecked={setChecked} realUser={realUser} />
    </MapContainer>
  );
}
